import { useContext, useState } from "react";
import axios from "axios";
import { API_URL } from "src/scenes/App";
import { UserContext } from "src/scenes/App/UserContext";
import { Toast } from "src/components";
import { EventType } from "matrix-js-sdk";
import { USER_TYPE_EMPLOYEES, USER_TYPE_CLIENTS } from "src/constants/chat";
import { downloadUserKeysForDecryption } from "src/utils/helpers";

/**
 * Custom hook for getting chats (employee, clients or leadership role).
 * Also updates a Chat's `latestEvent` once received by timeline listener.
 *
 * @typedef {import('matrix-js-sdk').MatrixClient} MatrixClient
 *
 * @param {MatrixClient} matrixClient
 * @returns {{
 *      chatList: Array
 *      didFetchChatsFail: boolean
 *      setDidFetchChatsFail: React.SetStateAction
 *      currentUserUuid: string
 *      businessBackgroundColor: string?
 *      isFetchingChats: boolean
 *      retrieveChats: ((function(string): Promise<void>))
 *      removeChatFromChatlist: ((function(string): void))
 *      setChatList: React.SetStateAction
 *      populateActiveChatListDetails: void    
 *      activeChatListDetails: Array
 *      setActiveChatListDetails: React.SetStateAction
 *      updateActiveList: (function(object): void)
 * 
 * }}
 */
export const useGetChats = (matrixClient, leadershipRoles) => {

    const userContext = useContext(UserContext);

    const [chatList, setChatList] = useState([]);
    const [currentUserUuid, setCurrentUserUuid] = useState("");
    const [businessBackgroundColor, setBusinessBackgroundColor] = useState(null);
    const [isFetchingChats, setIsFetchingChats] = useState(true);
    const [didFetchChatsFail, setDidFetchChatsFail] = useState(false);
    const [activeChatListDetails, setActiveChatListDetails] = useState([]);
    
    const headers = { headers: { Authorization: "Bearer " + userContext.token } };

    /**
     * Also retrieves company's background color to be used by ChatList
     * @param {string} selectedChatListTypeOrLeadershipUuid
     * @returns {Promise<void>}
     */
    async function fetchData(selectedChatListTypeOrLeadershipUuid) {
        if (!selectedChatListTypeOrLeadershipUuid) {
            console.warn("No selected chat type to fetch in fetchData().", selectedChatListTypeOrLeadershipUuid);
            return;
        }
        
        const isNonLeadership = [USER_TYPE_CLIENTS, USER_TYPE_EMPLOYEES].includes(selectedChatListTypeOrLeadershipUuid);
        const url = isNonLeadership ? selectedChatListTypeOrLeadershipUuid : `getLeadershipChats/${selectedChatListTypeOrLeadershipUuid}`;
        const latestResponse = await axios.get(`${API_URL}/chats/${url}`, headers);
        return { 
            label: selectedChatListTypeOrLeadershipUuid, 
            url: url, 
            data: latestResponse.data 
        };
    }

    const retrieveChats = async (selectedChatListTypeOrLeadershipUuid) => {
        if (!selectedChatListTypeOrLeadershipUuid) {
            Toast.error("No selected chat type to fetch.");
            return;
        }

        try {
            setIsFetchingChats(true);
            const activeChatDetails = await fetchData(selectedChatListTypeOrLeadershipUuid);
            return activeChatDetails.data;
        } catch (error) {
            setDidFetchChatsFail(true);
            Toast.error("Failed to retrieve chats.");
            console.warn("Failed to retrieve chats", error);
        } finally {
            setIsFetchingChats(false);
        }
    };

    const updateActiveList = async (data, handleOpenChat) => {
        setBusinessBackgroundColor(data.businessBackgroundColor);
        setCurrentUserUuid(data.currentUserUuid);

        const formattedChats = await getChatsLatestEvent(data.chats);
        const sorted = sortByLatestEventTimestamp(formattedChats);

        setChatList(sorted);
        handleOpenChat(sorted[0]);
    };

    const getActiveChatList = () => {
        const userTypeUrls = [
            `${USER_TYPE_EMPLOYEES}`,
            `${USER_TYPE_CLIENTS}`,
        ];

        let leadershipRoleUrls = [];
        if (leadershipRoles.length > 0) {
            leadershipRoleUrls = leadershipRoles.map(role => { return role.uuid; });
        }

        // Merge the arrays using spread operator
        return [...userTypeUrls, ...leadershipRoleUrls];
    };

    const populateActiveChatListDetails = async () => {
        const activeChatList = getActiveChatList();

        setIsFetchingChats(true);
        const fetchDataResponses = await Promise.all(activeChatList.map(fetchData));
        setActiveChatListDetails(fetchDataResponses);
        setIsFetchingChats(false);

        return fetchDataResponses; //for initial loading
    };

    /**
     * @typedef {import('matrix-js-sdk').Room} Room
     * @param {Room} room
     */
    const getRecentMessageEventInTheRoom = (room) => {
        try {
            // Get or create a filtered timeline set using the filter
            const latestRoomMessageEvent = room.getLiveTimeline().getEvents().findLast(matrixEvent => matrixEvent.getType() === EventType.RoomMessage);

            // Check if the filteredTimeline is empty
            if (!latestRoomMessageEvent) {
                return "No messages yet";
            }

            return latestRoomMessageEvent.getContent().body;
        } catch (e) {
            console.warn("Error in `getRecentMessageEventInTheRoom`", e);
            return "Unable to retrieve recent message";
        }
    };

    /**
     * Get the last event of the matrix room, used to display contents for ChatsList
     * @param {array} chats
     * @returns array
     */
    const getChatsLatestEvent = async (chats) => {
        if (!chats) {
            return [];
        }
        
        if (!matrixClient) {
            return [];
        }

        const formattedChats = await Promise.all(
            chats.map(async (chat) => {
                const room = matrixClient.getRoom(chat.matrixRoomId);

                if (room) {
                    await downloadUserKeysForDecryption(matrixClient, room);
                    const latestMatrixEvent = room.getLastLiveEvent();
                    if (!latestMatrixEvent) {
                        console.warn("No latest event found in this room.");
                        return undefined;
                    }
                    chat["latestEvent"] = latestMatrixEvent; //TODO: create another object for custom field
                    chat["unreadCount"] = room.getUnreadNotificationCount("total"); 
                    chat["recentMessage"] = getRecentMessageEventInTheRoom(room); //TODO: create another object for custom field
                    return chat;
                }
            })
        );

        return formattedChats.filter((item) => item !== undefined);
    };

    /**
     * @param {string} chatRoomUuidToRemove
     */
    const removeChatFromChatlist = (chatRoomUuidToRemove, handleOpenChat) => {
        const removedChat = chatList.filter((chat) => chat.uuid !== chatRoomUuidToRemove);
        setChatList(removedChat);
        handleOpenChat(null);
    };

    return {
        chatList,
        didFetchChatsFail,
        setDidFetchChatsFail,
        currentUserUuid, 
        businessBackgroundColor, 
        isFetchingChats,
        retrieveChats,
        removeChatFromChatlist,
        setChatList,
        populateActiveChatListDetails,
        activeChatListDetails,
        setActiveChatListDetails,
        updateActiveList,
        getRecentMessageEventInTheRoom
    };
};

/**
 *
 * @param {Array} chats
 * @returns {Array}
 */
const sortByLatestEventTimestamp = (chats) => {
    return chats.sort((a, b) => {
        const timestampA = new Date(a?.latestEvent.getTs());
        const timestampB = new Date(b?.latestEvent.getTs());
        return timestampB - timestampA;
    });
};