import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { Button, FlexContainer, LottieAnimation } from "src/components";
import PurpleLottieJson from "src/scenes/Noticeboard/assets/purple-lottie.json";
import Checkbox from "src/scenes/EmployeeList/components/Checkbox";
import styled from "styled-components";
import SearchIcon from "src/img/new/search.svg";
import DropdownIcon from "src/img/new/drop-down.svg";
import moment from "moment";
import { PERK_STATUS, PERK_TYPE } from "src/constants/perks";
import InsightModal from "./InsightModal";
import UpArrow from "src/img/employeeList/SortUpArrow.svg";
import DownArrow from "src/img/employeeList/SortDownArrow.svg";

const TableControls = styled.div`
    position: relative;
    width: 100%;
    max-width: 94.5rem;
    background: #fff;
    height: auto;
    min-height: 5.625rem;
    display: flex;
    align-items: center;
    border-radius: 7px 7px 0 0;
    border: 1px solid #8291B2;
    border-bottom: none;
    box-sizing: border-box;
`;

const SearchInput = styled.input`
    position:relative;
    background-image: url(${SearchIcon});
    background-repeat: no-repeat;
    text-indent: 25px;
    background-position: 16px center;
    line-height: 100%;
    width: 100%;
    border: 1px solid #000;
    max-width: 31.75rem;
    height: 2.438rem;
    margin-left: 1.4375rem;
    margin-right: 1.4375rem;
    border-radius: 10px;
    font-size: 0.875rem;
    text-align: left;
    padding: 0 0 0 1rem;
    box-sizing: border-box;
    ::placeholder {
        text-align: left;
        font-weight: 700;
        font-size: 0.875rem;
    }

    &:focus {
        outline: none;
    }

    @media (max-width: 1440px) {
        max-width: 24.75rem;
    }

    @media (max-width: 1024px) {
        max-width: 20.75rem;
        margin-right: 0.5rem;
    }
`;

const TableFilter = styled.select`
    height: 2.438rem;
    width: 11.125rem;
    border-radius: 7px;
    background: #fff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #000;
    padding: 0.3em 1.1em;
    color: #000;
    font-size: 0.875rem;
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: 700;
    background-image: url(${DropdownIcon});
    background-repeat: no-repeat;
    background-position-x: 97%;
    background-position-y: 50%;
    appearance: none;

    &:focus {
        outline: none;
    }

    @media (max-width: 1440px) {
        width: 10rem;
    }

    @media (max-width: 1024px) {
        width: 8rem;
    }
`;

const CreateButton = styled(Button)`
    border-radius: 10px !important;
    border: none;
    height: 2.4375rem;
    width: 13.125rem;
    color: #fff;
    background-color: #006cff;
    box-sizing: border-box;
    font-size: 0.75rem;
    font-weight: 500;
    margin-left: 1.4375rem;
    margin-right: 1.4375rem;
    padding: 0 !important;

    @media (max-width: 1440px) {
        width: 10.125rem;
    }

    @media (max-width: 1024px) {
        margin-left: 0.5rem;
    }
`;

const DeleteButton = styled(Button)`
    border-radius: 10px !important;
    border: none;
    height: 2.4375rem;
    width: 10.75rem;
    color: #fff;
    background-color: #ff7171;
    box-sizing: border-box;
    font-size: 0.75rem;
    font-weight: 500;
    padding: 0 !important;

    @media (max-width: 1440px) {
        width: 9.75rem;
    }
`;

const Th = styled.th`
    width: ${p => p.width};
    text-align: ${p => p.textAlign || "left"};
`;

const Td = styled.td`
    padding: 0 1em !important;
    height: 3.938rem;
    font-size: 0.75rem !important;
    white-space: normal !important;
    text-align: ${p => p.textAlign || "left"};
`;

const TdButton = styled.button`
    height: 2rem;
    background-color: ${p => p.backgroundColor};
    color: ${p => p.color};
    border: ${p => p.border};
    border-radius: 7px;
    width: 6.25rem;
    cursor: pointer;
    font-weight: 700;
    font-size: 0.75rem;
`;

const PerkImgContainer = styled.div`
    width: 83px;
    height: 47px;
    display: flex;
    justify-content: center;
    border: 1px solid #8291B2;
    border-radius: 7px;
`;

const PerkImg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 7px;
    align-self: center;
`;

const SORTABLE_COLUMNS = {
    COMPANY_NAME: "companyName",
    END_DATE: "endDate"
};

const SORT_DIRECTION = {
    ASC: "asc",
    DESC: "desc"
};

const PerksTable = ({
    user,
    perks,
    handleSearchChange,
    handleFilterByStatus,
    searchQuery,
    filterStatus,
    isFetching,
    allPerksChecked,
    handleCheckAllPerks,
    checkedPerks,
    handleCheckPerk,
    handleShowDeleteDialog,
    handleFormVisible,
    handleEditPerk
}) => {

    const [showInsightsModal, setShowInsightsModal] = useState(false);
    const [selectedPerk, setSelectedPerk] = useState(null);
    const [sortConfig, setSortConfig] = useState({ key: SORTABLE_COLUMNS.END_DATE, direction: SORT_DIRECTION.DESC });

    const handleShowInsightModal = perk => {
        setSelectedPerk(perk);
        setShowInsightsModal(true);
    };

    const handleCloseInsightModal = () => {
        setSelectedPerk(null);
        setShowInsightsModal(false);
    };

    const trimText = text => {
        if (text && text.length > 80) {
            return text.slice(0, 80).trim() + "...";
        }
        return text ?? "";
    };

    const formatExpiryDate = date => {
        const currentDate = moment();
        const expirationDate = moment(date);
        if (currentDate.isAfter(expirationDate)) {
            return expirationDate.format("MMMM D, YYYY");
        }
        return expirationDate.format("MMMM D, YYYY");
    };

    const promoType = perk => {
        return perk.promoCode ? "Text" : "Image";
    };

    const renderExtraRows = () => {
        const minRows = 10;
        const noOfRowsToRender = minRows - perks.length;
        const extraRows = [];

        for (let i = 0; i < noOfRowsToRender; i++) {
            extraRows.push(
                <tr key={`empty-${i}`}>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                </tr>
            );
        }

        return extraRows;
    };

    const renderGroup = perk => {
        const employees = [];
        const clients = [];

        perk.selectedCompanyRecipients.forEach(user => {
            user.isClient ? clients.push(user) : employees.push(user);
        });

        const createParagraph = text => (
            <p>
                {text.split("\n").map((line, index) => (
                    <React.Fragment key={index}>
                        {line}
                        {index < text.split("\n").length - 1 && <br />}
                    </React.Fragment>
                ))}
            </p>
        );

        if (perk.type === PERK_TYPE.GENERAL) {
            if (employees.length > 0 && clients.length > 0) {
                return createParagraph("Employees & Clients");
            } else if (employees.length > 0) {
                return createParagraph("Employees");
            } else if (clients.length > 0) {
                return createParagraph("Clients");
            }
        }

        if (perk.type === PERK_TYPE.GIFT) {
            if (employees.length > 0 && clients.length > 0) {
                let text = "Gift";
                if (employees.length === 1) {
                    text = `${text} (Employee)\n${employees[0].employeeName}`;
                } else {
                    text = `${text} (Employee)\n${employees.length} Employees`;
                }
                if (clients.length === 1) {
                    text = `${text}\n(Client)\n${clients[0].employeeName}`;
                } else {
                    text = `${text}\n(Client)\n${clients.length} Clients}\n`;
                }
                return createParagraph(text);
            } else if (employees.length > 0) {
                if (employees.length === 1) {
                    return createParagraph(`Gift (Employee)\n${employees[0].employeeName}`);
                } else {
                    return createParagraph(`Gift (Employee)\n${employees.length} Employees`);
                }
            } else if (clients.length > 0) {
                if (clients.length === 1) {
                    return createParagraph(`Gift (Client)\n${clients[0].employeeName}`);
                } else {
                    return createParagraph(`Gift (Client)\n${clients.length} Clients`);
                }
            }
        }
    };

    const sortPerks = (perks) => {
        if (!sortConfig.key) return perks;

        return [...perks].sort((a, b) => {
            const aValue = sortConfig.key === SORTABLE_COLUMNS.END_DATE ? new Date(a[sortConfig.key]) : a[sortConfig.key]?.toLowerCase();
            const bValue = sortConfig.key === SORTABLE_COLUMNS.END_DATE ? new Date(b[sortConfig.key]) : b[sortConfig.key]?.toLowerCase();

            if (aValue < bValue) return sortConfig.direction === SORT_DIRECTION.ASC ? -1 : 1;
            if (aValue > bValue) return sortConfig.direction === SORT_DIRECTION.ASC ? 1 : -1;
            return 0;
        });
    };

    const handleSort = (key) => {
        setSortConfig((prevConfig) => ({
            key,
            direction: prevConfig.key === key && prevConfig.direction === SORT_DIRECTION.ASC ? SORT_DIRECTION.DESC : SORT_DIRECTION.ASC,
        }));
    };

    const sortedPerks = sortPerks(perks);

    return (
        <>
            { isFetching && (
                <FlexContainer
                    justifyContent="center"
                    alignItems="center"
                    color="#fff"
                    style={{
                        maxWidth: "94.5rem",
                        height: "44.188rem",
                        border: "1px solid #8291B2",
                        borderRadius: "7px"
                    }}
                >
                    <LoadingIndicator />
                </FlexContainer>
            )}

            { !isFetching && (
                <>
                    <TableControls>
                        <Grid item md={7}>
                            <SearchInput placeholder="Search Perk" type="text" value={searchQuery} onChange={handleSearchChange} />
                            <TableFilter value={filterStatus} onChange={handleFilterByStatus}>
                                <option value={PERK_STATUS.ACTIVE}>Active</option>
                                <option value={PERK_STATUS.EXPIRED}>Expired</option>
                            </TableFilter>
                        </Grid>

                        <Grid item md={5}>
                            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                                {checkedPerks.length > 0 && (
                                    <DeleteButton onClick={() => handleShowDeleteDialog()}>
                                        Delete Selected Perks
                                    </DeleteButton>
                                )}
                                <CreateButton onClick={handleFormVisible}>
                                    Create New Perk
                                </CreateButton>
                            </Grid>
                        </Grid>
                    </TableControls>

                    <div className="scrollable-table" style={{ maxWidth: "94.5rem" }}>
                        <table>
                            <thead>
                                <tr>
                                    <Th width="4%" textAlign="center">
                                        <label>
                                            <Checkbox
                                                key="selectAll"
                                                readOnly={false}
                                                checked={allPerksChecked}
                                                onChange={handleCheckAllPerks}
                                            />
                                        </label>
                                    </Th>
                                    <Th width="9%">Image</Th>
                                    <Th
                                        width="16%"
                                        onClick={() => handleSort(SORTABLE_COLUMNS.COMPANY_NAME)}
                                        style={{ cursor: "pointer" }}
                                    >
                                        Company Name &nbsp;
                                        {sortConfig.key === SORTABLE_COLUMNS.COMPANY_NAME &&
                                            (sortConfig.direction === SORT_DIRECTION.ASC ? <img src={UpArrow} /> : <img src={DownArrow} />)}
                                    </Th>
                                    <Th width="25%">Heading</Th>
                                    <Th width="9%">Code</Th>
                                    <Th
                                        width="9%"
                                        onClick={() => handleSort(SORTABLE_COLUMNS.END_DATE)}
                                        style={{ cursor: "pointer" }}
                                    >
                                        Perk Expiry &nbsp;
                                        {sortConfig.key === SORTABLE_COLUMNS.END_DATE &&
                                            (sortConfig.direction === SORT_DIRECTION.ASC ? <img src={UpArrow} /> : <img src={DownArrow} />)}
                                    </Th>
                                    <Th width="10%">User Group</Th>
                                    <Th width="9%" textAlign="center">Insights</Th>
                                    <Th width="9%" textAlign="center">Edit Perk</Th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedPerks.map(perk => (
                                    <tr key={perk.uuid}>
                                        <Td textAlign="center">
                                            <label>
                                                <Checkbox
                                                    readOnly={false}
                                                    checked={checkedPerks.includes(perk.uuid)}
                                                    onChange={(e) => handleCheckPerk(e, perk.uuid)}
                                                />
                                            </label>
                                        </Td>
                                        <Td>
                                            <PerkImgContainer>
                                                <PerkImg src={perk.image.imageUrl} />
                                            </PerkImgContainer>
                                        </Td>
                                        <Td>{perk.companyName}</Td>
                                        <Td>{trimText(perk.title)}</Td>
                                        <Td>{promoType(perk)}</Td>
                                        <Td>{formatExpiryDate(perk.endDate)}</Td>
                                        <Td>{renderGroup(perk)}</Td>
                                        <Td textAlign="center">
                                            <TdButton
                                                backgroundColor="#eaf3ff"
                                                color="#006cff"
                                                border="1px solid #006cff"
                                                onClick={() => handleShowInsightModal(perk)}
                                            >
                                                View
                                            </TdButton>
                                        </Td>
                                        <Td textAlign="center">
                                            <TdButton
                                                backgroundColor="#fff"
                                                color="#000"
                                                border="1px solid #000"
                                                onClick={() => handleEditPerk(perk)}
                                            >
                                                Edit
                                            </TdButton>
                                        </Td>
                                    </tr>
                                ))}

                                {renderExtraRows()}
                            </tbody>
                        </table>
                    </div>
                </>
            )}

            { showInsightsModal &&
                <InsightModal
                    user={user}
                    perk={selectedPerk}
                    showInsightModal={showInsightsModal}
                    handleCloseInsightModal={handleCloseInsightModal}
                />
            }
        </>
    );
};

const LoadingIndicator = () => {
    return (
        <FlexContainer>
            <LottieAnimation animationJsonData={PurpleLottieJson} />
        </FlexContainer>
    );
};

export default PerksTable;