import React, { useState, useEffect, useContext } from "react";
import { ChatContext } from "./ChatContext";
import { FlexContainer, LoadingIndicator, ErrorLabel, Toast } from "src/components";
import axios from "axios";
import { ClientEvent, SyncState } from "matrix-js-sdk";

/**
 * fix for runtime error caused by crypto
 * see https://stackoverflow.com/questions/68707553/uncaught-referenceerror-buffer-is-not-defined
 */
import { Buffer } from "buffer";
window.Buffer = Buffer;

import { ONE_ON_ONE_CHAT, GROUP_CHAT, USER_TYPE_CLIENTS, USER_TYPE_EMPLOYEES } from "src/constants/chat";
import EditGroup from "./components/EditGroupChatModal";
import ChatMessages from "./components/ChatMessages";
import CreateChatModal from "./components/CreateChatModal";
import DeleteConfirmation from "./components/DeleteConfirmation";
import ProfileSettingsModal from "./components/ProfileSettingsModal";
import LeadershipEmployees from "./components/LeadershipEmployees";
import ChatList from "./components/ChatList";
import { MainContainer, LeftContainer, RightContainer } from "./components/styled/shared";

import {
    useAbortController,
    useMatrixClient,
    useGetChats,
    useChatListUnread,
    useGetLeadershipRoles,
    useCreateChat,
    useUpdateChat,
    useDeleteChat,
    useTypingListener,
    usePresenceListener,
    useFetchUsersList
} from "./hooks";
import BackupKeyModal from "./components/children/backupkey/BackupKeyModal";
import { API_URL } from "../App";
import { BackupKeyContext } from "./BackupKeyContext";
import useTimelineListener from "./hooks/matrix/useTimelineListener";
import { UserContext } from "src/scenes/App/UserContext";
import { UserKeys } from "src/constants/userDetails";

export const BACKUP_KEY_FORMS = {
    CREATE: "CREATE",
    RESET: "RESET",
    RESTORE: "RESTORE"
};

export const BACKUP_STORAGE_KEYS = {
    CREATE_BACKUP_REFUSED: "createBackupPasswordRefused",
    USER_KEY_RESTORED: "userKeyRestored"
};

const Chat = (props) => {

    const { abort } = useAbortController();
    const currentUser = useContext(UserContext);
    const { updateUserContextProperties } = useContext(UserContext);
    const headers = { headers: { Authorization: "Bearer " + currentUser.token } };
    const { 
        matrixClient, 
        isInitialSyncComplete, 
    } = useMatrixClient(headers);

    const {
        leadershipRoles,
        leadershipToShow,
        isFetchingLeadership,
        retrieveLeadershipRoles,
    } = useGetLeadershipRoles();

    const {
        chatList,
        didFetchChatsFail,
        currentUserUuid,
        businessBackgroundColor,
        retrieveChats,
        removeChatFromChatlist,
        setChatList,
        populateActiveChatListDetails,
        activeChatListDetails,
        updateActiveList,
        getRecentMessageEventInTheRoom,
        isFetchingChats
    } = useGetChats(matrixClient, leadershipRoles);

    const {
        updateActiveChatListTotalUnread,
        activeChatsTotalUnread,
    } = useChatListUnread(matrixClient, activeChatListDetails);

    const { isTyping, typingMember, typingRoomId } = useTypingListener(matrixClient);

    const [selectedChatListTypeOrLeadershipUuid, setSelectedChatListTypeOrLeadershipUuid] = useState(USER_TYPE_EMPLOYEES);
    const [selectedChatListTypeOrLeadershipUuidLabel, setSelectedChatListTypeOrLeadershipUuidLabel] = useState(USER_TYPE_EMPLOYEES);
    const [openedChat, setOpenedChat] = useState(null);
    const [chatToCreate, setChatToCreate] = useState({
        showModal: false,
        employeesToChat: [],
        chatType: "" // group or single/one-on-one
    });
    const [showEditGroupChatModal, setShowEditGroupChatModal] = useState(false);
    const [profileModalIsShown, setProfileModalIsShown] = useState(false);
    const [profileModalShowsCurrentUser, setProfileModalShowsCurrentUser] = useState(false);
    const [profileModalUser, setProfileModalUser] = useState(null);
    const [profileModalUserLeadershipRoles, setProfileModalUserLeadershipRoles] = useState(null);
    const [showBackupKeyForm, setShowBackupKeyForm] = useState(false);
    const [backupFormToShow, setBackupFormToShow] = useState("");
    const [userHasBackupKeys, setUserHasBackupKeys] = useState(false);
    const [toggleDropdown, setToggleDropdown] = useState(false);

    const { createChatHook } = useCreateChat();
    const { updateChatHook, isUpdatingChat } = useUpdateChat();
    const { deleteChat, isDeletingChat, showConfirmationModal, setShowConfirmationModal } = useDeleteChat();
    const { fetchUsersList } = useFetchUsersList();
    // to show page load only on first fetch. subsequent fetch and create should only show loading state on the ChatList component
    const [isInitialMount, setIsInitialMount] = useState({ chatList: true, backupKey: true });
    // to show LoadingIndicator on ChatList and ChatMessage when fetching, creating or updating chat
    const [showLoadingState, setShowLoadingState] = useState(false);
    const [isCurrentlyCreatingChat, setIsCurrentlyCreatingChat] = useState(false);
    const [usersList, setUsersList] = useState([]);
    const [showLeadershipEmployees, setShowLeadershipEmployees] = useState(false);
    const [isUpdatingChatlist, setIsUpdatingChatlist] = useState(false);
    const [syncTimeStamp, setSyncTimeStamp] = useState(null);
    const { 
        newEvent,
        matrixRoomIdToRemove,
        roomWithMemberChange,
        resetRoomWithMemberChange
    } = useTimelineListener(matrixClient, isInitialSyncComplete);

    const retrieveUsersList = async (fetchUserType) => {
        const users = await fetchUsersList(fetchUserType, headers);
        if (users && users.length > 0) {
            setUsersList(users);
        }
    };

    /** this will retrieve all user's details and permissions */
    const getUserDetails = async () => {
        const currentUserData = await props.getUserDetails(currentUser.token);
        updateUserContextProperties({
            [UserKeys.MATRIX_USER_ID]: currentUserData.matrixUserId,
            [UserKeys.FIRST_NAME]: currentUserData.firstName,
            [UserKeys.LAST_NAME]: currentUserData.lastName,
            [UserKeys.PROFILE_PICTURE_URL]: currentUserData.profilePictureUrl,
            [UserKeys.PERMISSIONS]: JSON.parse(currentUserData.roleAndPermissions.permissionIds),
            [UserKeys.LEADERSHIP_ROLES]: currentUserData.leadershipRoles,
            [UserKeys.CHAT_PRESENCE_OVERRIDE]: currentUserData.chatPresenceOverride,
            [UserKeys.PHONE_NUMBER]: currentUserData.phoneNumber,
            [UserKeys.COUNTRY_CODE]: currentUserData.countryCode,
        });
    };


    const { onlineUsersMatrixId } = usePresenceListener(matrixClient, isInitialSyncComplete, headers, currentUser, retrieveUsersList, selectedChatListTypeOrLeadershipUuid, didFetchChatsFail);

    useEffect(() => {
        /**
         * used to change the background color of <LoggedInRightContainer>
         * when rendering the Chat component. revert back to original background color upon unmount */
        props.isChatRendered(true);
        getUserDetails();
        retrieveLeadershipRoles();
        return () => {
            props.isChatRendered(false);
        };
    }, []);

    useEffect(() => {
        const fetchActiveListDetails = async () => {
            const initialActiveChatListDetails = await populateActiveChatListDetails(leadershipRoles);
            if (initialActiveChatListDetails.length === 0) {
                console.warn("Empty Active Chat List", initialActiveChatListDetails);
                return;
            }

            const preSelectedActiveChat = initialActiveChatListDetails[0];
            const isNonLeadership = [USER_TYPE_CLIENTS, USER_TYPE_EMPLOYEES].includes(preSelectedActiveChat.label);
            if (isNonLeadership) {
                handleChatListSelectLabel(preSelectedActiveChat.label);
                updateActiveList(preSelectedActiveChat.data, handleOpenChat);
                return;
            }

            //leadership
            handleChatListSelectLabel(preSelectedActiveChat.label);
            updateActiveList(preSelectedActiveChat.data, handleOpenChat);
            setIsInitialMount(prev => ({
                ...prev,
                chatList: false
            }));
        };

        const runBackupPassword = async () => {
            const ifBackupKeysExist = await checkIfUserHasExistingBackupKeys();
            const status = checkIfUserAlreadyRefusedToCreateBackupPassword();
            const ifLocalStorageHasRestoredUserKey = localStorage.getItem(BACKUP_STORAGE_KEYS.USER_KEY_RESTORED);
            if (!status && !ifBackupKeysExist) {
                setShowBackupKeyForm(true);
                setBackupFormToShow(BACKUP_KEY_FORMS.CREATE);
            }
            if (!status && ifBackupKeysExist && !ifLocalStorageHasRestoredUserKey) {
                setShowBackupKeyForm(true);
                setBackupFormToShow(BACKUP_KEY_FORMS.RESTORE);
            }

            setIsInitialMount(prev => ({
                ...prev,
                backupKey: false
            }));
        };
        
        if (isInitialSyncComplete && matrixClient) {
            const runInitialProcess = async () => {
                await Promise.all([fetchActiveListDetails(), runBackupPassword()]);
                setSyncTimeStamp(Date.now()); //initial
                setIsInitialMount(false);
                
                //add Sync Listener
                matrixClient.on(ClientEvent.Sync, (state, prevState, res) => {
                    if (state !== SyncState.Syncing)
                        return;

                    setSyncTimeStamp(Date.now()); //on sync
                });
            };
            
            runInitialProcess();
        }

        return abort();
    }, [isInitialSyncComplete, matrixClient]);

    useEffect(() => {
        if (syncTimeStamp) {
            updateActiveChatListTotalUnread();
        }
    }, [syncTimeStamp]);
    
    useEffect(() => {
        if (isUpdatingChat && !showLoadingState) {
            setShowLoadingState(true);
        }
        else {
            setShowLoadingState(false);
        }
    }, [isUpdatingChat]);

    useEffect(() => {
        const handleChatMemberChange = async () => {
            try {
                const index = chatList.findIndex(chat => chat.matrixRoomId === roomWithMemberChange);
                if (index === -1) return;
                const response = await axios.get(`${API_URL}/chats/chatByMatrixRoomId/${roomWithMemberChange}`, headers);
                const newChatList = [...chatList];
                newChatList[index].users = response.data.chat.users;
                setChatList(newChatList);
            } catch (error) {
                Toast.error("Unable to update chat members, will retrieve chats.");
                const latestResponseData = await retrieveChats(selectedChatListTypeOrLeadershipUuid);
                updateActiveList(latestResponseData, handleOpenChat);
            } finally {
                resetRoomWithMemberChange();
            }
        };

        if (roomWithMemberChange && isInitialSyncComplete) {
            handleChatMemberChange();
        }
    }, [roomWithMemberChange]);

    const handleChatListSelectLabel = (selectedValue) => {
        const isNonLeadership = [USER_TYPE_CLIENTS, USER_TYPE_EMPLOYEES].includes(selectedValue);
        
        if (isNonLeadership) {
            //setOpenedChat(null); TODO: remove this after testing on STAGING
            setSelectedChatListTypeOrLeadershipUuid(selectedValue);
            setSelectedChatListTypeOrLeadershipUuidLabel(selectedValue);
            return;
        }

        if (Array.isArray(leadershipRoles)) {
            const selectedLeadershipRoleItem = leadershipRoles.find((item) => item.uuid === selectedValue);
            if (selectedLeadershipRoleItem) {
                const selectedLeadershipRoleName = selectedLeadershipRoleItem.name;
                //setOpenedChat(null); TODO: remove this after testing on STAGING
                setSelectedChatListTypeOrLeadershipUuid(selectedValue);
                setSelectedChatListTypeOrLeadershipUuidLabel(selectedLeadershipRoleName);
                return;
            }
        }

        console.warn("Unknown Active Chat List has been selected: ", selectedValue, leadershipRoles);
    };

    const handleChatListSelectChange = (selectedValue) => {
        handleChatListSelectLabel(selectedValue);
        updateListOfChats(selectedValue);
    };

    const updateListOfChats = async (chatListTypeToShow) => {
        setIsUpdatingChatlist(true);
        const latestResponseData = await retrieveChats(chatListTypeToShow);
        updateActiveList(latestResponseData, handleOpenChat);
        setIsUpdatingChatlist(false);
    };

    const createChatWithLeadership = async (formData) => {
        try {
            setChatToCreate({ ...chatToCreate, chatType: ONE_ON_ONE_CHAT });
            const created = await createChatHook(formData, chatToCreate, headers);
            handleCreateChatResponse(created, true);
        } catch (error) {
            Toast.error(error.message || "Unable to create a new chat with a leadership employee.");
        }
    };

    const handleOpenChat = async (chat) => {
        setShowLeadershipEmployees(false);
        setOpenedChat(chat);

        //run this silently
        if (chat?.unreadCount !== undefined && chat.unreadCount !== 0) {
            const hasSentReceipt = await sendReadReceipt(chat.matrixRoomId);
            if (!hasSentReceipt) {
                return;
            }
            chat.unreadCount = 0;
        }
    };

    const sendReadReceipt = async (matrixRoomId) => {
        try {
            const room = await matrixClient.getRoom(matrixRoomId);
            const lastEvent = room.getLastLiveEvent();
            const url = `${API_URL}/_matrix/client/v3/rooms/${room.roomId}/receipt/m.read/${lastEvent.getId()}`;

            const formData = {
                "next_token": "abcdef",
                "notifications": [
                    {
                        "actions": ["notify"],
                        "event": lastEvent,
                        "profile_tag": "",
                        "read": true,
                        "room_id": room.roomId,
                        "ts": Date.now()
                    }
                ]
            };

            await axios.post(url, formData, headers);
            return true;
        } catch (error) {
            Toast.error("Did not find a valid room for this id. Unable to process request.");
            return false;
        }
    };

    const handleCreateChat = async (formData) => {
        if (isCurrentlyCreatingChat) {
            Toast.error("Creating a new chat in progress.");
            return;
        }

        const isGroupChat = chatToCreate.chatType === GROUP_CHAT;
        if (isGroupChat && formData.employeeUuidsToChat.length < 2) {
            Toast.error("A group chat requires a minimum of 2 users.");
            return;
        }

        setIsCurrentlyCreatingChat(true);
        try {
            const created = await createChatHook(formData, chatToCreate, headers);
            closeCreateChatModal();
            handleCreateChatResponse(created, false);
        } catch (error) {
            Toast.error(error);
        } finally {
            setIsCurrentlyCreatingChat(false);
        }
    };

    const updateGroupChat = async (formData) => {
        setShowEditGroupChatModal(false);
        try {
            const updated = await updateChatHook(openedChat.uuid, formData, headers);
            handleUpdateChatResponse(updated);
        } catch (error) {
            Toast.error(error.message || "Unable to update chat.");
        }
    };

    const handleUpdateChatResponse = async (response) => {
        const index = chatList.findIndex(chat => chat.uuid === response.chat.uuid);
        if (index !== -1) {
            chatList[index].users = response.chat.users;
            chatList[index].name = response.chat.name;
            handleOpenChat(response.chat);
            return;
        }

        Toast.error("Failed to locate updated chat. Will retrieve list.");
        const latestResponseData = await retrieveChats(selectedChatListTypeOrLeadershipUuid);
        updateActiveList(latestResponseData, handleOpenChat);
    };

    const insertNewlyCreatedChat = async (chat, chatList) => {

        let updatedChatList = chatList;
        const listIndex = chatList.findIndex((chatItem) => chatItem.uuid === chat.uuid);
        //when chatList already exist, overwrite latestEvent, and open the ChatMessage
        if (listIndex !== -1) {
            chat.latestEvent = updatedChatList[listIndex].latestEvent; //TODO: create another object for custom field
            updatedChatList[listIndex] = chat;
            setChatList(updatedChatList);
            handleOpenChat(chat);
            return;
        }

        //add the chatList in the array and open the ChatMessage
        updatedChatList.unshift(chat);
        setChatList(updatedChatList);
        handleOpenChat(chat);
    };

    const handleCreateChatResponse = (response) => {
        let newlyCreatedChat = response.chat;
        const chatBelongsInList = (chat, listType) => {
            if (chat.leadershipRoleUuid !== "") {
                return chat.leadershipRoleUuid === listType;
            }
            const chatType = chat.isClient ? USER_TYPE_CLIENTS : USER_TYPE_EMPLOYEES;
            return chatType === listType;
        };

        if (response.isNew) {
            /**
             * validate if the selectedChatListTypeOrLeadershipUuid is correct 
             * in order to prevent any unintended insertion for the active chat's list of chats
            */ 
            newlyCreatedChat = { ...newlyCreatedChat, recentMessage: "No messages yet" };
            if (chatBelongsInList(newlyCreatedChat, selectedChatListTypeOrLeadershipUuid)) {
                insertNewlyCreatedChat(newlyCreatedChat, chatList);
            }
        }

        if (!response.isNew && chatBelongsInList(newlyCreatedChat, selectedChatListTypeOrLeadershipUuid)) {
            const room = matrixClient.getRoom(response.chat.matrixRoomId);
            newlyCreatedChat = { ...newlyCreatedChat, recentMessage: getRecentMessageEventInTheRoom(room) ?? "..." };
            insertNewlyCreatedChat(newlyCreatedChat, chatList);
        }
    };

    const handleChatDeletion = async () => {
        setToggleDropdown(false);
        const response = await deleteChat(openedChat.uuid, headers);
        if (response && response.status === 200) {
            removeChatFromChatlist(openedChat.uuid, handleOpenChat);
        } else {
            console.warn("handleChatDeletion encounted an error with the response.", response);
        }
    };

    const checkIfUserHasExistingBackupKeys = async () => {
        const url = `${API_URL}/_matrix/client/v3/room_keys/version`;
        try {
            const response = await axios.get(url, headers);
            if (response.data.auth_data) {
                setUserHasBackupKeys(true);
                return true;
            }
        } catch (error) {
            // these means that logged in user is most likely a new user that is yet to create back up keys
            setUserHasBackupKeys(false);
            setBackupFormToShow(BACKUP_KEY_FORMS.CREATE);
            return false;
        }
    };

    const checkIfUserAlreadyRefusedToCreateBackupPassword = () => {
        return localStorage.getItem(BACKUP_STORAGE_KEYS.CREATE_BACKUP_REFUSED);
    };

    const showCreateChatModalOrShowLeadershipEmployees = (chatParticipantType, selectedChatListTypeOrLeadershipUuid) => {
        const isNonLeadership = [USER_TYPE_CLIENTS, USER_TYPE_EMPLOYEES].includes(selectedChatListTypeOrLeadershipUuid);
       
        if (isNonLeadership) { //creating a chat that is not leadershiprole related
            setChatToCreate({
                ...chatToCreate,
                chatType: chatParticipantType,
                showModal: true
            });
            return;
        }

        const leadershipRoleIndex = leadershipRoles.findIndex((item) => item.uuid === selectedChatListTypeOrLeadershipUuid);
        if (leadershipRoleIndex === -1) {
            console.warn("Unable to create chat for the selected Active Chat List: ", chatParticipantType, selectedChatListTypeOrLeadershipUuid, leadershipRoles);
        }

        const activeLeadershipRole = leadershipRoles[leadershipRoleIndex];

        if (activeLeadershipRole.currentUserIsLeader) { //viewing as a leadership leader
            setChatToCreate({
                ...chatToCreate,
                chatType: chatParticipantType,
                showModal: true
            });
            return;
        }

        //viewing as non-leader, show Leadership employee
        setOpenedChat(null);
        setShowLeadershipEmployees(true);
    };

    const closeCreateChatModal = () => {
        setChatToCreate({
            ...chatToCreate,
            showModal: false
        });
    };

    const displayOtherUserProfileModal = () => {
        if (openedChat.isGroupChat) {
            return Toast.error("This feature is not available on group chats.");
        }
        const otherChatUser = openedChat.users.filter(user => user.employeeUuid !== currentUserUuid);
        displayProfileModal(otherChatUser[0].employeeDetails, otherChatUser[0].leadershipRoles, false);
    };

    /**
     * @param {object} userToShow
     * @param {array} userLeadershipRoles
     * @param {boolean} isCurrentUser
     */
    const displayProfileModal = (userToShow, userLeadershipRoles, isCurrentUser) => {
        setProfileModalUser(userToShow);
        setProfileModalUserLeadershipRoles(userLeadershipRoles);
        setProfileModalIsShown(true);
        setProfileModalShowsCurrentUser(isCurrentUser);
    };

    const closeProfileModal = () => {
        setProfileModalIsShown(false);
    };

    const handleShowBackupKeyModal = () => {
        const form = userHasBackupKeys ? BACKUP_KEY_FORMS.RESTORE : BACKUP_KEY_FORMS.CREATE;
        setBackupFormToShow(form);
        setShowBackupKeyForm(true);
    };


    const handleShowEditGroupChatModal = () => setShowEditGroupChatModal(true);

    const handleCloseBackupKeyForm = () => {
        if (!userHasBackupKeys && backupFormToShow === BACKUP_KEY_FORMS.CREATE) {
            localStorage.setItem(BACKUP_STORAGE_KEYS.CREATE_BACKUP_REFUSED, 1);
        }
        setShowBackupKeyForm(false);
    };

    const handleSettingCurrentUserStatus = (status) => {
        props.updateUserPresenceState(status);
    };

    if (isInitialMount.chatList && isInitialMount.backupKey) {
        return <LoadingIndicator />;
    }

    return (
        <React.Fragment>
            <FlexContainer direction="row" height="100%">
                {didFetchChatsFail ?
                    <FlexContainer marginTop="3rem" justifyContent="center" alignItems="center" width="100%" height="100%">
                        <ErrorLabel>Something went wrong!</ErrorLabel>
                    </FlexContainer>
                    :
                    <React.Fragment>
                        <ChatContext.Provider value={{
                            showLoadingState: showLoadingState,
                            currentUserEmployeeUuid: currentUserUuid,
                            currentChat: openedChat,
                            handleOpenChat: handleOpenChat,
                            showCreateChatModalOrShowLeadershipEmployees: showCreateChatModalOrShowLeadershipEmployees,
                            chatToCreate: chatToCreate,
                            selectedChatListTypeOrLeadershipUuid: selectedChatListTypeOrLeadershipUuid,
                            handleChatListSelectChange: handleChatListSelectChange,
                            displayProfileModal: displayProfileModal,
                            handleShowBackupKeyModal: handleShowBackupKeyModal,
                            leadershipRoles: leadershipRoles,
                            leadershipToShow: leadershipToShow,
                            matrixClient: matrixClient,
                            isTyping: isTyping,
                            typingMember: typingMember,
                            typingRoomId: typingRoomId,
                            // passing the new event here to trigger a re-render on the ChatMessages' roomTimeline.
                            // since this component does not use `useFetchRoomTimeline`.
                            newTimelineEvent: newEvent,
                            onlineUsersMatrixId: onlineUsersMatrixId,
                            handleSettingCurrentUserStatus: handleSettingCurrentUserStatus,
                            usersList: usersList,
                            showLeadershipEmployees: showLeadershipEmployees,
                            userChatsList: chatList,
                            setChatList: setChatList,
                            isUpdatingChatlist: isUpdatingChatlist,
                            retrieveChats: retrieveChats,
                            updateActiveList: updateActiveList,
                            activeChatListDetails: activeChatListDetails,
                            activeChatsTotalUnread: activeChatsTotalUnread,
                            sendReadReceipt: sendReadReceipt,
                            updateListOfChats: updateListOfChats,
                            isFetchingChats: isFetchingChats
                        }}>
                            <MainContainer> 
                                <LeftContainer>
                                    { !isInitialMount.chatList && <ChatList 
                                        businessBackgroundColor={businessBackgroundColor}
                                        matrixRoomIdToRemove={matrixRoomIdToRemove}
                                        isCurrentlyCreatingChat={isCurrentlyCreatingChat}
                                    /> }
                                </LeftContainer>
                                <RightContainer>
                                    { isInitialMount.chatList || isFetchingLeadership || isCurrentlyCreatingChat || isUpdatingChatlist ? 
                                        <LoadingIndicator /> 
                                        :
                                        showLeadershipEmployees ?
                                            <LeadershipEmployees
                                                createChatWithLeadership={createChatWithLeadership}
                                                selectedChatListTypeOrLeadershipUuidLabel={selectedChatListTypeOrLeadershipUuidLabel}
                                                isChatListEmpty={chatList.length === 0}
                                            /> 
                                            :
                                            <ChatMessages showEditGroupChatModal={handleShowEditGroupChatModal}
                                                deleteChat={() => setShowConfirmationModal(true)}
                                                hasChats={chatList.length > 0}
                                                toggleDropdown={toggleDropdown}
                                                setToggleDropdown={setToggleDropdown}
                                                showUserProfile={displayOtherUserProfileModal}
                                                handleChatDeletion={handleChatDeletion}
                                            />
                                    }
                                </RightContainer>
                            </MainContainer>

                            {chatToCreate.showModal &&
                                <CreateChatModal showDialog={chatToCreate.showModal}
                                    handleClose={() => closeCreateChatModal()}
                                    handleCreateChat={(data) => handleCreateChat(data)}
                                    isCurrentlyCreatingChat={isCurrentlyCreatingChat}
                                />
                            }
                            {showEditGroupChatModal &&
                                <EditGroup showDialog={showEditGroupChatModal}
                                    handleClose={() => setShowEditGroupChatModal(false)}
                                    updateGroupChat={updateGroupChat}
                                    deleteChat={() => setShowConfirmationModal(true)}
                                />
                            }
                            {profileModalIsShown &&
                                <ProfileSettingsModal
                                    handleClose={closeProfileModal}
                                    isCurrentUser={profileModalShowsCurrentUser}
                                    userToShow={profileModalUser}
                                    profileModalUserLeadershipRoles={profileModalUserLeadershipRoles}
                                />
                            }
                            {showConfirmationModal &&
                                <DeleteConfirmation
                                    showDialog={showConfirmationModal}
                                    handleClose={() => setShowConfirmationModal(false)}
                                    confirm={handleChatDeletion}
                                    isDeletingChat={isDeletingChat}
                                />
                            }
                            { showBackupKeyForm && backupFormToShow &&
                                <BackupKeyContext.Provider value={{
                                    backupFormToShow: backupFormToShow,
                                    updateBackupKeyFormToShow: (data) => setBackupFormToShow(data),
                                    handleClose: handleCloseBackupKeyForm
                                }}>
                                    <BackupKeyModal showDialog={showBackupKeyForm} />
                                </BackupKeyContext.Provider>
                            }
                        </ChatContext.Provider>
                    </React.Fragment>
                }
            </FlexContainer>
        </React.Fragment>
    );
};

export default Chat;