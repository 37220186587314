import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { API_URL } from "src/scenes/App";
import { LottieLoadingIndicator, PageContainer, PageHeadingLabel } from "src/components";
import { FormBackground } from "./components/Utils";
import SurveyTable from "./components/SurveyTable";
import HowItWorks from "./components/HowItWorks";
import SurveyInsightPage from "./components/SurveyInsightPage";
import SurveyForm from "./forms/SurveyForm";

import "./css/SurveyStyling.css";
import { SurveyStatusType, SortableColumns, SortableColumnState, TableType, SortablePollColumns } from "src/constants/survey";
import PollsTable from "./components/PollsTable";
import PollsForm from "./forms/PollsCreateForm";

const Survey = (props) => {
    const [fetching, setFetching] = useState(false);
    const [surveyArray, setSurveyArray] = useState([]);
    const [pollsArray, setPollsArray] = useState([]);
    const [sortableColumns, setSortableColumns] = useState([]);
    const [isShowSurveyForm, setIsShowSurveyForm] = useState(false);
    const [surveyToEdit, setSurveyToEdit] = useState("");
    const [isShowHowItWorks, setIsShowHowItWorks] = useState(false);
    const [isShowInsights, setIsShowInsights] = useState(false);
    const [surveyUuid, setSurveyUuid] = useState("");
    const [initialized, setInitialized] = useState(false);
    const [isShowPolls, setIsShowPolls] = useState(false);
    const [isShowCreatePollForm, setIsShowCreatePollForm] = useState(false);
    const [pollToEdit, setPollToEdit] = useState("");
    const [selectedSurveyOrPollStatusType, setSelectedSurveyOrPollStatusType] = useState(SurveyStatusType.ACTIVE);
    const controllerRef = useRef(new AbortController());

    useEffect(() => {
        safeToInit();
    }, []);

    const safeToInit = () => {
        getSurveys(SurveyStatusType.ACTIVE);
    };


    const getSurveys = (surveyType) => {
        if (controllerRef.current) {
            controllerRef.current.abort();
        }

        controllerRef.current = new AbortController();
        setFetching(true);
        let URL = `${API_URL}/survey/company/allSurveys`;
        if (surveyType) {
            URL += `?filter=${surveyType}`;
        }
        axios.get(URL, {
            headers: {
                Authorization: `Bearer ${props.user.token}`
            },
            signal: controllerRef.current.signal
        }).then(res => {
            setSurveyArray(res.data);
            setSortableColumns(prevColumns => ({
                ...prevColumns,
                [SortableColumns.SURVEY_NAME]: SortableColumnState.ASCENDING
            }));
            setFetching(false);
            if (!initialized) {
                setInitialized(true);
            }
        }).catch(error => {
            if (axios.isCancel(error)) console.error(error.message || error.response.data.error);
        });
    };

    const getPolls = (pollType) => {
        if (controllerRef.current) {
            controllerRef.current.abort();
        }

        controllerRef.current = new AbortController();
        setFetching(true);
        let URL = `${API_URL}/polls/companyPolls`;
        if (pollType) {
            URL += `?filter=${pollType}`;
        }
        axios.get(URL, {
            headers: {
                Authorization: `Bearer ${props.user.token}`
            },
            signal: controllerRef.current.signal
        }).then(res => {
            setPollsArray(res.data.polls);
            setSortableColumns(prevColumns => ({
                ...prevColumns,
                [SortablePollColumns.POLL_QUESTION]: SortableColumnState.ASCENDING
            }));
            setFetching(false);
            setInitialized(true);
        }).catch(error => {
            if (axios.isCancel(error)) {
                console.error(error.message || error.response.data.error);
            }
        });
    };

    const showCreatePollForm = (isShow) => {
        setPollToEdit("");
        setIsShowCreatePollForm(isShow);
    };

    const showEditPollForm = (pollUuid) => {
        setPollToEdit(pollUuid);
        setIsShowCreatePollForm(true);
    };

    const showSurveyForm = (isShow) => {
        setSurveyToEdit("");
        setIsShowSurveyForm(isShow);
        getSurveys(selectedSurveyOrPollStatusType);
    };

    const showEditSurveyForm = (surveyUuid) => {
        setSurveyToEdit(surveyUuid);
        setIsShowSurveyForm(true);
    };

    const showHowItWorks = (isShow) => setIsShowHowItWorks(isShow);
    const surveyInsights = (uuid) => {
        setIsShowInsights(true);
        setSurveyUuid(uuid);
    };
    const showInsights = (isShow) => setIsShowInsights(isShow);
    const setFetchingStatus = (status) => setFetching(status);

    const sortField = (sortableState, fieldName) => {
        const arrayToSort = isShowPolls ? pollsArray : surveyArray;
        const sortedArray = [...arrayToSort].sort((a, b) => (
            sortableState === SortableColumnState.ASCENDING
                ? a[fieldName] > b[fieldName] ? 1 : -1
                : a[fieldName] < b[fieldName] ? 1 : -1
        ));
        isShowPolls ? setPollsArray(sortedArray) : setSurveyArray(sortedArray);
    };

    const sortColumns = (columnIndex) => {
        const newSortableColumns = { ...sortableColumns };
        let fieldName = "";

        Object.keys(newSortableColumns).forEach((_, i) => {
            newSortableColumns[i] = i === columnIndex
                ? (newSortableColumns[columnIndex] === SortableColumnState.ASCENDING
                    ? SortableColumnState.DESCENDING
                    : SortableColumnState.ASCENDING)
                : SortableColumnState.INACTIVE;
        });

        if (isShowPolls) {
            fieldName = getFieldNameForPollColumn(columnIndex);
        } else {
            fieldName = getFieldNameForSurveyColumn(columnIndex);
        }

        setSortableColumns(newSortableColumns);

        if (fieldName) {
            sortField(newSortableColumns[columnIndex], fieldName);
        }
    };

    const getFieldNameForPollColumn = (columnIndex) => {
        switch (columnIndex) {
            case SortablePollColumns.POLL_QUESTION: return "question";
            case SortablePollColumns.POLL_VISIBILITY: return "pollVisibility";
            case SortablePollColumns.NUMBER_OF_OPTIONS: return "numberOfOptions";
            case SortablePollColumns.START_DATE: return "startDate";
            case SortablePollColumns.FINISH_DATE: return "endDate";
            case SortablePollColumns.STATUS: return "status";
            default: return "";
        }
    };

    const getFieldNameForSurveyColumn = (columnIndex) => {
        switch (columnIndex) {
            case SortableColumns.SURVEY_NAME: return "name";
            case SortableColumns.SURVEY_TYPE: return "surveyType";
            case SortableColumns.USER_GROUP: return "userGroup";
            case SortableColumns.START_DATE: return "startDate";
            case SortableColumns.FINISH_DATE: return "finishDate";
            case SortableColumns.STATUS: return "status";
            default: return "";
        }
    };

    const setSurveyStatusType = (statusType) => setSelectedSurveyOrPollStatusType(statusType);

    const handleChangeTableType = (tableType) => {
        if (tableType === TableType.Surveys) {
            setShowPolls(false);
            getSurveys(selectedSurveyOrPollStatusType);
        } else {
            setShowPolls(true);
            getPolls(selectedSurveyOrPollStatusType);
        }
    };

    const setShowPolls = (isShow) => setIsShowPolls(isShow);


    const { user } = props;
    if (fetching > 0 && !initialized) {
        return (
            <PageContainer>
                <PageHeadingLabel size="2.5rem">Surveys & Polls</PageHeadingLabel>
                <div className="row" style={{ maxWidth: "94.5rem" }}>
                    <div className="description" style={{ marginBottom: "1em" }}>
                        Effortlessly create short and effective surveys or polls. Send them directly to your employees&apos; or clients&apos; Me <br />
                        Business app. Get instant feedback and data in real-time, staying informed and in control of your survey and poll<br />
                        campaign&apos;s performance.
                    </div>
                </div>
                <FormBackground style={{ height: "600px" }}>
                    <LottieLoadingIndicator />
                </FormBackground>
            </PageContainer>
        );
    }

    if (isShowSurveyForm) {
        return <SurveyForm showSurveyForm={showSurveyForm} user={user} getSurveys={getSurveys} surveyToEdit={surveyToEdit} />;
    }

    if (isShowCreatePollForm) {
        return <PollsForm showPollForm={showCreatePollForm} user={user} setShowPolls={setShowPolls} getPolls={getPolls} pollToEdit={pollToEdit} setPollToEdit={setPollToEdit} setSelectedSurveyOrPollStatusType={setSelectedSurveyOrPollStatusType} />;
    }

    if (isShowInsights) {
        return <SurveyInsightPage showInsights={showInsights} user={user} surveyUuid={surveyUuid} />;
    }

    if (isShowHowItWorks) {
        return <HowItWorks showHowItWorks={showHowItWorks} />;
    }

    if (isShowPolls) {
        return <PollsTable
            handleChangeTableType={handleChangeTableType}
            selectedTableType={isShowPolls ? TableType.Polls : TableType.Surveys}
            pollsArray={pollsArray}
            sortColumn={(key) => sortColumns(SortablePollColumns[key])}
            sortableColumns={sortableColumns}
            showPollForm={showCreatePollForm}
            showEditPollForm={showEditPollForm}
            showHowItWorks={showHowItWorks}
            surveyInsights={surveyInsights}
            fetchPoll={getPolls}
            fetching={fetching} user={user}
            setFetchingStatus={setFetchingStatus}
            setSurveyStatusType={setSurveyStatusType}
            selectedSurveyOrPollStatusType={selectedSurveyOrPollStatusType}
        />;
    }

    return <SurveyTable
        handleChangeTableType={handleChangeTableType}
        selectedTableType={isShowPolls ? TableType.Polls : TableType.Surveys}
        surveyArray={surveyArray}
        sortableColumns={sortableColumns}
        sortColumn={(key) => sortColumns(SortableColumns[key])}
        showSurveyForm={showSurveyForm}
        editSurvey={showEditSurveyForm}
        showHowItWorks={showHowItWorks}
        surveyInsights={surveyInsights}
        fetchSurvey={getSurveys}
        fetching={fetching} user={user}
        setFetchingStatus={setFetchingStatus}
        setSurveyStatusType={setSurveyStatusType}
        selectedSurveyOrPollStatusType={selectedSurveyOrPollStatusType}
    />;
};

export default withRouter(Survey);