import { useState } from "react";
import { API_URL } from "src/scenes/App";
import axios from "axios";
import { EventType } from "matrix-js-sdk";
import { Toast } from "src/components";

/**
 * @typedef {import('matrix-js-sdk').MatrixClient} MatrixClient
 * @param {MatrixClient} matrixClient
 * @returns {{
 *      roomTimeline: array
 *      isFetchingRoomTimeline: boolean
 *      didTimelineFetchFail: boolean
 *      isFetchingPaginatedEvents: boolean
 *      fetchPaginatedEvents: Function
 *      initialFetchRoomTimeline: Function
 *      roomTimelineEnd: string | undefined    // for pagination: attach `from=roomTimelineEnd` to route query. undefined means no more events to fetch
 *      roomsEvents: array
 * }}
 */
export const useFetchRoomTimeline = (matrixClient) => {

    const [roomTimeline, setRoomTimeline] = useState([]);
    const [isFetchingRoomTimeline, setIsFetchingRoomTimeline] = useState(false);
    const [didTimelineFetchFail, setDidTimelineFetchFail] = useState(false);
    const [isFetchingPaginatedEvents, setIsFetchingPaginatedEvents] = useState(false);
    const [roomTimelineEnd, setRoomTimelineEnd] = useState("");

    const initialFetchRoomTimeline = async (matrixRoomId, limit, headers) => {
        setIsFetchingRoomTimeline(true);
        setRoomTimeline([]);
        let initialRoomTimeline = [];
        const room = matrixClient.getRoom(matrixRoomId);
 
        try
        {
            if (!room) { //null when new
                throw new Error("Room is empty");
            }
            
            room.getLiveTimeline().getEvents().forEach(async (matrixEvent) => {
                if (matrixEvent.getType() === EventType.RoomMessage) {
                    initialRoomTimeline.push(matrixEvent);
                }
            });
            setRoomTimeline(initialRoomTimeline.reverse()); //container css has column-reverse
        } catch (e) {
            console.warn("Unable to render the chat for this room", e, matrixRoomId, room);
        } finally {
            setIsFetchingRoomTimeline(false);
        }
    };

    const fetchPaginatedEvents = async (timeline, matrixRoomId, limit, headers, from) => {
        if (from) {
            setIsFetchingPaginatedEvents(true);
            const roomTimelineEvents = await handleTimelineFetchAndProcessing(matrixRoomId, limit, headers, from);
            const combined = [...timeline, ...roomTimelineEvents];

            setRoomTimeline(combined);
            setIsFetchingPaginatedEvents(false);
        }
    };

    const handleTimelineFetchAndProcessing = async (matrixRoomId, limit, headers, from) => {
        setDidTimelineFetchFail(false);
        try {
            const roomTimeline = await fetchTimeline(matrixRoomId, limit, headers, from);
            setRoomTimelineEnd(roomTimeline.end);
            return roomTimeline.events;
        } catch (error) {
            Toast.error(error.message);
            setDidTimelineFetchFail(true);
        }
    };

    /**
     * push new event to the start of the timeline array
     * @param {array} currentTimeline 
     * @param {MatrixEvent} newTimelineEvent 
     */
    const updateTimelineWithNewEvent = (currentTimeline, newTimelineEvent) => {
        let newTimeline = [...currentTimeline];
        newTimeline.unshift(newTimelineEvent);

        const sortedTimeline = sortEventsByTimestamp(newTimeline);
        setRoomTimeline(sortedTimeline);
    };

    const sortEventsByTimestamp = (events) => {
        return events.sort((a, b) => {
            const aTimestamp = a.getTs();
            const bTimestamp = b.getTs();
      
            // Ensure both timestamps are defined before comparison
            if (aTimestamp === undefined || bTimestamp === undefined) {
                console.warn("Unable to get timestamp in `sortEventsByTimestamp`");
            }
            
            if (aTimestamp === undefined) {
                // B timestamp value means "greater" than A; if both undefined they're the same (return 0)
                return bTimestamp === undefined ? 0 : 1;
            } else if (bTimestamp === undefined) {
                // B considered "lesser" than A, since A has a timestamp but not B
                return -1;
            }

            return bTimestamp - aTimestamp;
        });
    };

    return {
        roomTimeline,
        isFetchingRoomTimeline,
        didTimelineFetchFail,
        initialFetchRoomTimeline,
        isFetchingPaginatedEvents,
        updateTimelineWithNewEvent,
        fetchPaginatedEvents,
        roomTimelineEnd
    };
};

const fetchTimeline = async (matrixRoomId, limit, headers, from) => {
    try {
        const url = `${API_URL}/_matrix/client/v3/rooms/${matrixRoomId}/messages?dir=b&limit=${limit}&from=${from}`;
        const response = await axios.get(url, headers);
        return { events: response.data.chunk, end: response.data.end, start: response.data.start };
    } catch (error) {
        throw new Error("Failed to fetch matrix room timeline.");
    }
};
