import React, { useContext, useEffect, useRef, useState } from "react";
import { API_URL } from "src/scenes/App";
import axios from "axios";
import styled from "styled-components";
import {
    Toast, FlexContainer, FormField, ErrorText,
    Text, Form, PageHeadingLabel, PageContainer
} from "src/components";

import {
    CancelButton, SaveButton,
    FormBackground, FormSectionHeader, LoadingIndicatorButton,
    LoadingIndicatorIcon, AnswerContainer, AddAnotherAnswerContainer
} from "../components/Utils";
import { ErrorStrings, ErrorList } from "../components/Enums";
import ValidatedDateInput from "src/components/ValidatedDateInput";
import UserGroup from "src/img/survey/updatedIcons/users.svg";
import ImageUpload from "./components/PollsImageUpload";
import DynamicPollAnswer from "./components/DynamicPollAnswer";
import { UserContext } from "src/scenes/App/UserContext";
import { UserKeys } from "src/constants/userDetails";
import usePolls from "./hooks/usePolls";
import LoadingForm from "./components/LoadingForm";
import { Switch, withStyles } from "@material-ui/core";
import { SurveyStatusType } from "src/constants/survey";
import PollVisibilitySection from "./components/PollVisibilitySection";

const PollNameField = (props) => {
    const { pollQuestion, inputSurveyName, inputErrors } = props;

    const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
    const pollNameError = getErrorStrings(ErrorList.EMPTY_POLL_QUESTION);

    return (
        <>
            <Text size="16px" align="left" weight="700">
                Create A Poll Question
            </Text>
            <Text size="14px" color="#8291B2" align="left">
                Enter the question you want to ask. This will be the main prompt for your poll.
            </Text>
            <FormField borderRadius="12px" size="16px" padding="10px 20px" height="50px"
                placeholderColor="#808080" border="1px solid #E9EBEF"
                placeholderAlignment="left"
                width="100%"
                type="text"
                style={{ textAlign: "left" }}
                placeholder="What’s your poll question?"
                onChange={(event) => { inputSurveyName(event); }}
                errors={pollNameError}
                maxLength="100"
                value={pollQuestion}
                showErrors={pollNameError.length > 0}
            >
            </FormField>
        </>
    );
};

const PollDurationFields = (props) => {
    const { startsAt, endsAt, inputStartDate, inputFinishDate, inputErrors } = props;

    const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);

    const surveyStartDateError = getErrorStrings(ErrorList.EMPTY_START_DATE);
    const surveyFinishDateError = getErrorStrings(ErrorList.EMPTY_FINISH_DATE);

    const startDateInvalid = getErrorStrings(ErrorList.DATE_START_DATE_INVALID);
    const finishDateInvalid = getErrorStrings(ErrorList.DATE_FINISH_DATE_INVALID);

    const dateStartFormatInvalid = getErrorStrings(ErrorList.DATE_START_FORMAT_INVALID);
    const dateFinishFormatInvalid = getErrorStrings(ErrorList.DATE_FINISH_FORMAT_INVALID);

    const dateRangeInvalid = getErrorStrings(ErrorList.DATE_RANGE_INVALID);

    const hasAnySurveyDurationErrors = (surveyStartDateError.length > 0 || surveyFinishDateError.length > 0 || startDateInvalid.length > 0 || finishDateInvalid.length > 0 || dateStartFormatInvalid.length > 0 || dateFinishFormatInvalid.length > 0 || dateRangeInvalid.length > 0);

    return (
        <>
            <Text color="#000" align="left" size="16px" weight="500" margin="16px 0 16px 0">
                Set Poll Duration
            </Text>
            <Text color="#8291B2" align="left" size="14px" margin="11px 0 18px 0">
                Set the start and end dates for your  Votes will only be accepted within this timeframe.
            </Text>
            <FlexContainer align="center" style={{ flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap", columnGap: "24px" }}>
                <div style={{ textAlign: "center", marginLeft: "0px", flex: "1 1" }}>
                    <ValidatedDateInput
                        placeholder="Select start date"
                        onChange={(event) => { inputStartDate(event); }}
                        errors={surveyStartDateError}
                        value={startsAt}
                        disabled={false}
                        showErrors={surveyStartDateError.length > 0}
                    />
                </div>
                <div style={{ textAlign: "center", marginLeft: "0px", flex: "1 1" }}>
                    <ValidatedDateInput
                        placeholder="Select end date"
                        onChange={(event) => { inputFinishDate(event); }}
                        errors={surveyFinishDateError}
                        value={endsAt}
                        disabled={false}
                        showErrors={surveyFinishDateError.length > 0}
                    />
                </div>
            </FlexContainer>

            {hasAnySurveyDurationErrors &&
                <ErrorText errorMargin="0px">
                    {startDateInvalid.length > 0 ? startDateInvalid : null}
                    {finishDateInvalid.length > 0 ? finishDateInvalid : null}
                    {dateStartFormatInvalid.length > 0 ? dateStartFormatInvalid : null}
                    {dateFinishFormatInvalid.length > 0 ? dateFinishFormatInvalid : null}
                    {dateRangeInvalid.length > 0 ? dateRangeInvalid : null}
                </ErrorText>
            }
        </>
    );
};

const PollResultVisibility = (props) => {
    const { showSurveyResults, onToggle } = props;

    const ResultsToggler = styled.div`
        width: 340px;
        height: 50px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #DBE5ED;
        border-radius: 10px;
        opacity: 1;
        display: flex;
        justify-content: space-between;
        padding: 0 16px;
        font: normal normal medium 14px/19px Roboto;
    `;

    const ToggleAllUsersSwitch = withStyles((theme) => ({
        root: {
            width: 51,
            height: 31,
            padding: 0,
            marginTop: "auto",
            marginBottom: "auto",
        },
        switchBase: {
            padding: 1,
            "&$checked": {
                transform: "translateX(19px)",
                color: theme.palette.common.white,
                "& + $track": {
                    backgroundColor: "#52d869",
                    opacity: 1,
                    border: "none",
                },
            },
            "&$focusVisible $thumb": {
                color: "#52d869",
                border: "6px solid #fff",
            },
        },
        thumb: {
            width: 29,
            height: 29,
        },
        track: {
            borderRadius: 29 / 2,
            backgroundColor: theme.palette.grey[200],
            opacity: 1,
            transition: theme.transitions.create(["background-color", "border"]),
        },
        checked: {},
        focusVisible: {},
    }))(({ classes, ...props }) => {
        return (
            <Switch
                focusVisibleClassName={classes.focusVisible}
                disableRipple
                classes={{
                    root: classes.root,
                    switchBase: classes.switchBase,
                    thumb: classes.thumb,
                    track: classes.track,
                    checked: classes.checked,
                }}
                {...props}
            />
        );
    });

    return (
        <>
            <Text color="#000" align="left" size="16px" weight="500" margin="16px 0 16px 0">
                Poll Result Visibility
            </Text>
            <Text color="#8291B2" align="left" size="14px" margin="11px 0 18px 0">
                Decide whether to display the current poll results to users while the poll is still active or hide the results until the poll concludes.
            </Text>

            <ResultsToggler>
                <span style={{ margin: "auto 0" }}>Show Current Results off/on</span>
                <ToggleAllUsersSwitch onChange={onToggle} checked={showSurveyResults} />
            </ResultsToggler>
        </>
    );
};

const PollVisibility = (props) => {
    const {
        togglePollVisibilityModal,
        showPollVisibilityModal,
        handleSavePollVisibility,
        clientsInPoll,
        employeesInPoll,
        employeesCountInPoll,
        clientsCountInPoll,
        pollRespondentsUuids,
        isEditForm,
        inputErrors,
    } = props;

    const SelectedPeopleIcon = styled.div`
        width: 40px;
        height: 40px;
        background: #D6D6D6 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border: 2px solid #FFFFFF;
        opacity: 1;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        margin-top: auto;
        margin-bottom: auto;
    `;

    const SelectedPeopleItem = styled.div`
        width: 283px;
        height: 59px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 5px 5px #0000001A;
        border: 1px solid #E0E0E0;
        border-radius: 15px;
        opacity: 1;
        display: flex;
        flex-direction: row;
        padding-left: 14px;
        margin-bottom: 9px;
    `;

    const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
    const pollVisibilityError = getErrorStrings(ErrorList.EMPTY_POLL_PARTICIPANTS);

    return (
        <>
            <Text align="left" size="16px" weight="bold">
                Poll Visibility
            </Text>
            <Text size="14px" color="#8291B2" align="left">
                Choose who can participate in this poll.
            </Text>
            {((clientsInPoll || clientsCountInPoll !== 0) || (employeesInPoll || employeesCountInPoll !== 0)) &&
                <FlexContainer direction="row" mDirection="row" tDirection="row" gap="1.5rem" style={{ marginBottom: "29px" }}>
                    {(clientsInPoll || clientsCountInPoll !== 0) &&
                        <SelectedPeopleItem>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <SelectedPeopleIcon>
                                    <img src={UserGroup} alt="peopleIcon" height="18px" style={{ margin: "auto" }} />
                                </SelectedPeopleIcon>
                                <Text size="16px" weight="500" style={{ marginLeft: "0.5rem" }} >
                                    {clientsInPoll ? "All" : clientsCountInPoll} Clients
                                </Text>
                            </div>
                        </SelectedPeopleItem>
                    }
                    {(employeesInPoll || employeesCountInPoll !== 0) &&
                        <SelectedPeopleItem>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <SelectedPeopleIcon>
                                    <img src={UserGroup} alt="peopleIcon" height="18px" style={{ margin: "auto" }} />
                                </SelectedPeopleIcon>
                                <Text size="16px" weight="500" style={{ marginLeft: "0.5rem" }} >
                                    {employeesInPoll ? "All" : employeesCountInPoll} Employees
                                </Text>
                            </div>
                        </SelectedPeopleItem>
                    }
                </FlexContainer>
            }

            <PollVisibilitySection
                togglePollVisibilityModal={togglePollVisibilityModal}
                showPollVisibilityModal={showPollVisibilityModal}
                handleSave={handleSavePollVisibility}
                pollRespondentsUuids={pollRespondentsUuids}
                clientsInPoll={clientsInPoll}
                employeesInPoll={employeesInPoll}
                employeesCountInPoll={employeesCountInPoll}
                clientsCountInPoll={clientsCountInPoll}
                isEditForm={isEditForm}
                modalTitle="Poll Visibility"
                pollVisibilityError={pollVisibilityError}
                label={isEditForm || (employeesCountInPoll !== 0 || clientsCountInPoll !== 0)
                    ? "Add/Edit People"
                    : "Add People"}
                showButton={true}
                buttonWidth="285px"
            />
        </>
    );
};

const SurveyQuestionsFields = (props) => {
    const { inputAnswerChange, pollOptions, activeAnswerCount, addAnswer, removeAnswer, isEditForm, isPollAnswersEditable, inputErrors } = props;
    const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
    const pollOptionsError = getErrorStrings(ErrorList.EMPTY_POLL_OPTIONS);

    return (
        <div style={{ width: "90%" }}>
            <FormSectionHeader style={{ fontWeight: "600" }}>
                Answer Options
            </FormSectionHeader>
            <Text size="14px" color="#8291B2" align="left">
                Provide the choices that participants can select from. You can add up to 10 options.
            </Text>
            <AnswerContainer 
                answerNumber={1} 
                answerValue={pollOptions[0].value} 
                inputAnswerChange={inputAnswerChange} 
                pollOptions={pollOptions}
                disabled={!isPollAnswersEditable && isEditForm }
                isEditForm={isEditForm}
                errors={pollOptionsError}
                showErrors={pollOptionsError.length > 0}
            />
            <AnswerContainer 
                answerNumber={2} 
                answerValue={pollOptions[1].value} 
                inputAnswerChange={inputAnswerChange} 
                pollOptions={pollOptions}
                disabled={!isPollAnswersEditable && isEditForm }
                isEditForm={isEditForm}
                errors={pollOptionsError}
                showErrors={pollOptionsError.length > 0}
            />
            {/* [...Array(8)] is used to shorten code in rendering 8 more poll answer entry*/}
            {[...Array(8)].map((_, index) => (
                <DynamicPollAnswer
                    key={index}
                    inputAnswerChange={inputAnswerChange}
                    pollOptions={pollOptions}
                    pollOptionIndex={index + 2}
                    activeAnswerCount={activeAnswerCount}
                    answerIndex={index + 3}
                    removeAnswer={removeAnswer}
                    disabled={!isPollAnswersEditable && isEditForm}
                    isEditForm={isEditForm}
                    errors={pollOptionsError}
                    showErrors={pollOptionsError.length > 0}
                />
            ))}

            {activeAnswerCount <= 9 && (!isPollAnswersEditable && !isEditForm) &&
                <AddAnotherAnswerContainer addAnswer={addAnswer} />
            }
        </div>
    );
};

const PollsForm = (props) => {
    const { showPollForm, setShowPolls, pollToEdit, setPollToEdit, getPolls, setSelectedSurveyOrPollStatusType } = props;
    const currentUser = useContext(UserContext);
    const { requestS3PresignedUrl, uploadToS3 } = usePolls(currentUser.token);
    const controllerRef = useRef(new AbortController());
    const [pollQuestion, setPollQuestion] = useState("");
    const [showPollVisibilityModal, setShowPollVisibilityModal] = useState(false);
    const [employeesInPoll, setEmployeesInPoll] = useState(false);
    const [clientsInPoll, setClientsInPoll] = useState(false);
    const [employeesCountInPoll, setEmployeesCountInPoll] = useState(0);
    const [clientsCountInPoll, setClientsCountInPoll] = useState(0);
    const [startsAt, setStartsAt] = useState("");
    const [endsAt, setEndsAt] = useState("");
    const [pollOptions, setPollOptions] = useState([
        { uuid: "", value: "" },
        { uuid: "", value: "" },
        { uuid: "", value: "" },
        { uuid: "", value: "" },
        { uuid: "", value: "" },
        { uuid: "", value: "" },
        { uuid: "", value: "" },
        { uuid: "", value: "" },
        { uuid: "", value: "" },
        { uuid: "", value: "" },
    ]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [inputErrors, setInputErrors] = useState([]);
    const [activeAnswerCount, setActiveAnswerCount] = useState(2);
    const [loading, setLoading] = useState(false);
    const [formImage, setFormImage] = useState(null);
    const [imageHasBeenUploaded, setImageHasBeenUploaded] = useState(false);
    const [imageChanged, setImageChanged] = useState(false);
    const [isFormSending, setIsFormSending] = useState(false);
    const [pollParticipantUuids, setPollParticipantUuids] = useState([]);
    const [isPollAnswersEditable, setIsPollAnswersEditable] = useState(false);
    const [showSurveyResults, setShowSurveyResults] = useState(true);

    const formTitle = pollToEdit !== "" ? "Edit Poll" : "Create New Poll";

    useEffect(() => {
        if (pollToEdit) {
            getPoll(pollToEdit);
        }
    }, [pollToEdit]);

    /** to retrieve a specific poll, used in edit poll view */
    const getPoll = (pollUuid) => {
        if (controllerRef.current) {
            controllerRef.current.abort();
        }

        controllerRef.current = new AbortController();
        setLoading(true);
        let URL = `${API_URL}/polls/companyPolls/${pollUuid}`;
        axios.get(URL, {
            headers: {
                Authorization: `Bearer ${props.user.token}`
            },
            signal: controllerRef.current.signal
        }).then(res => {
            const pollData = res.data.poll;

            setPollQuestion(pollData.question);
            setStartsAt(pollData.startDate);
            setEndsAt(pollData.endDate);
            setActiveAnswerCount(pollData.pollOptions.length);
            setPollOptions(pollData.pollOptions);

            if (!pollData.image.isUsingCompanyLogo) {
                setFormImage(pollData.image.imageUrl);
            }

            setEmployeesInPoll(pollData.pollVisibility.allEmployees === "All Employees");
            setClientsInPoll(pollData.pollVisibility.allClients === "All Clients");

            if (pollData.pollVisibility.allEmployees !== "All Employees" && pollData.pollVisibility.allEmployees) {
                setEmployeesCountInPoll(pollData.employeePollVisibilityCounter);
            }

            if (pollData.pollVisibility.allClients !== "All Clients" && pollData.pollVisibility.allClients) {
                setClientsCountInPoll(pollData.clientPollVisibilityCounter);
            }
            setPollParticipantUuids(pollData.pollRespondents);
            setSelectedUsers(pollData.pollRespondents);
            if (pollData.status === "Scheduled") {
                setIsPollAnswersEditable(true);
            }
            setShowSurveyResults(pollData.showPollResults);
        }).catch(error => {
            if (axios.isCancel(error)) {
                console.error(error.message || error.response.data.error);
            }
        }).finally(res => {
            setLoading(false);
        });
    };

    const inputPollName = (event) => {
        setPollQuestion(event.target.value);
    };

    const inputStartDate = (selectedDate) => {
        setStartsAt(selectedDate);
    };

    const inputFinishDate = (selectedDate) => {
        setEndsAt(selectedDate);
    };

    const inputAnswerChange = (answerIndex, event) => {
        const answerString = event.target.value;
        setPollOptions((prevOptions) => {
            const updatedOptions = [...prevOptions];
            const indexToUpdate = updatedOptions.findIndex((_, index) => index === answerIndex - 1);
        
            if (indexToUpdate !== -1) {
                updatedOptions[indexToUpdate] = {
                    ...updatedOptions[indexToUpdate],
                    value: answerString,
                };
            }
        
            return updatedOptions;
        });
    };

    const togglePollResultsVisibility = () => {
        setShowSurveyResults(!showSurveyResults);
    };

    const removeAnswer = (answerIndex) => {
        setPollOptions(prevOptions => ({
            ...prevOptions,
            [answerIndex]: ""
        }));
        setActiveAnswerCount(activeAnswerCount - 1);
    };

    const addAnswer = () => {
        setActiveAnswerCount(activeAnswerCount + 1);
    };

    const dataURLtoFile = (dataUrl, filename) => {
        const arr = dataUrl.split(",");
        const mime = arr[0].match(/:(.*?);/)[1];
        const bStr = atob(arr[1]);
        let n = bStr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bStr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    };

    const handleFormSubmission = () => {
        if (pollToEdit !== "") {
            formEditSubmission();
        } else {
            formCreateSubmission();  
        }
    };

    const validatePoll = (poll) => {
        const errors = [];

        if (!poll.question) {
            errors.push(ErrorList.EMPTY_POLL_QUESTION);
        }
        if (poll.participantUuids.length === 0) {
            errors.push(ErrorList.EMPTY_POLL_PARTICIPANTS);
        }
        if ((poll.options[0] === "" || poll.options[1] === "") || poll.options.length === 0) {
            errors.push(ErrorList.EMPTY_POLL_OPTIONS);
        }
        if (poll.startsAt && poll.endsAt && poll.startsAt > poll.endsAt) {
            errors.push(ErrorList.DATE_RANGE_INVALID);
        }
        if (!poll.startsAt) {
            errors.push(ErrorList.EMPTY_START_DATE);
        } else {
            if (isNaN(new Date(poll.startsAt))) {
                errors.push(ErrorList.DATE_START_DATE_INVALID);
            }
        }
        if (!poll.endsAt) {
            errors.push(ErrorList.EMPTY_FINISH_DATE);
        } else {
            if (isNaN(new Date(poll.endsAt))) {
                errors.push(ErrorList.DATE_FINISH_DATE_INVALID);
            }
        }
        return errors;
    };

    const formCreateSubmission = async () => {
        if (controllerRef.current) {
            controllerRef.current.abort();
        }

        let uploadedS3Url = "";
        setIsFormSending(true);
        const formatter = Intl.DateTimeFormat("fr-CA", { year: "numeric", month: "2-digit", day: "2-digit" });
        const formattedStartDate = startsAt ? formatter.format(new Date(startsAt)) : "";
        const formattedFinishDate = endsAt ? formatter.format(new Date(endsAt)) : "";
        let formattedPollOptions = [];

        if (Array.isArray(pollOptions)) {
            pollOptions.forEach((item) => {
                formattedPollOptions.push(item.value);
            });
        }

        let poll = {
            question: pollQuestion,
            startsAt: startsAt ? formattedStartDate + " 00:00:00" : null,
            endsAt: endsAt ? formattedFinishDate + " 23:59:59" : null,
            allEmployeesInPoll: employeesInPoll,
            allClientsInPoll: clientsCountInPoll,
            options: Object.values(formattedPollOptions).filter(value => value !== ""),
            participantUuids: selectedUsers,
            imageUrl: uploadedS3Url,
            showPollResults: showSurveyResults,
        };

        const errors = validatePoll(poll);

        if (errors.length > 0) {
            setInputErrors(errors);
            setIsFormSending(false);
            return;
        }
    

        async function processImageUpload(selectedImage) {
            const imageFile = dataURLtoFile(selectedImage, "polls-image");
            const imageFileType = imageFile.type.replace("image/", "");
            const pollsImagePresignedUrl = await requestS3PresignedUrl(imageFileType);

            const uploadedS3Url = await uploadToS3(pollsImagePresignedUrl, imageFile);

            setFormImage(uploadedS3Url);
            setImageHasBeenUploaded(true);

            return uploadedS3Url;
        }

        if (formImage && imageChanged) { // reader.result
            if (!imageHasBeenUploaded) {
                uploadedS3Url = await processImageUpload(formImage);
            }
        }

        poll.imageUrl = uploadedS3Url;

        controllerRef.current = new AbortController();
        axios.post(`${API_URL}/polls`, poll,
            {
                headers: { Authorization: `Bearer ${currentUser[UserKeys.TOKEN]}` },
                signal: controllerRef.current.signal
            }).then(res => {
            showPollForm(false);
            setShowPolls(true);
            setPollToEdit("");
            return Toast.success("Poll created successfully");
        }).catch(error => {
            setLoading(false);
            return Toast.error(error);
        }).finally(res => {
            setIsFormSending(false);
            setLoading(false);
            getPolls(SurveyStatusType.ACTIVE);
            setSelectedSurveyOrPollStatusType(SurveyStatusType.ACTIVE);
        });
    };

    const formEditSubmission = async () => {
        if (controllerRef.current) {
            controllerRef.current.abort();
        }
        setIsFormSending(true);
        let newUploadedS3Url = "";
        const formatter = Intl.DateTimeFormat("fr-CA", { year: "numeric", month: "2-digit", day: "2-digit" });
        const formattedStartDate = startsAt ? formatter.format(new Date(startsAt)) : "";
        const formattedFinishDate = endsAt ? formatter.format(new Date(endsAt)) : "";
        const mergedParticipantUuids = [...selectedUsers];

        let formRequest = {
            question: pollQuestion,
            startsAt: startsAt ? formattedStartDate + " 00:00:00" : null,
            endsAt: endsAt ? formattedFinishDate + " 23:59:59" : null,
            allEmployeesInPoll: employeesInPoll,
            allClientsInPoll: clientsCountInPoll,
            options: Object.values(pollOptions).filter(item => item.value !== ""),
            participantUuids: mergedParticipantUuids,
            showPollResults: showSurveyResults,
        };

        const errors = validatePoll(formRequest);

        if (errors.length > 0) {
            setInputErrors(errors);
            setIsFormSending(false);
            setLoading(false);
            return;
        }

        async function processImageUpload(selectedImage) {
            const imageFile = dataURLtoFile(selectedImage, "polls-image");
            const imageFileType = imageFile.type.replace("image/", "");
            const pollsImagePresignedUrl = await requestS3PresignedUrl(imageFileType);

            const uploadedS3Url = await uploadToS3(pollsImagePresignedUrl, imageFile);

            setFormImage(uploadedS3Url);
            setImageHasBeenUploaded(true);

            return uploadedS3Url;
        }

        if (imageChanged) {
            if (!imageHasBeenUploaded) {
                newUploadedS3Url = await processImageUpload(formImage);
            }
        }

        formRequest.imageUrl = newUploadedS3Url;

        controllerRef.current = new AbortController();
        axios.put(`${API_URL}/polls/${pollToEdit}`, formRequest,
            {
                headers: { Authorization: `Bearer ${currentUser[UserKeys.TOKEN]}` },
                signal: controllerRef.current.signal
            }
        ).then(res => {
            showPollForm(false);
            setShowPolls(true);
            setPollToEdit("");
            return Toast.success("Poll updated successfully");
        }).catch(error => {
            setLoading(false);
            return Toast.error(error);
        }).finally(res => {
            setIsPollAnswersEditable(false);
            setIsFormSending(false);
            setLoading(false);
            getPolls(SurveyStatusType.ACTIVE);
            setSelectedSurveyOrPollStatusType(SurveyStatusType.ACTIVE);
        });
    };

    const handleCancelButton = () => {
        showPollForm(false);
        setPollToEdit("");
    };

    const togglePollVisibilityModal = () => {
        setShowPollVisibilityModal(!showPollVisibilityModal);
    };

    /**
     * sets values from the poll visibility modal values
     * @param {boolean} allEmployeesSelected 
     * @param {boolean} allClientsSelected 
     * @param {array} selectedUsers 
     */
    const handleSavePollVisibility = (allEmployeesSelected, allClientsSelected, selectedUsers, employeesCountSelected, clientsCountSelected) => {
        setEmployeesInPoll(allEmployeesSelected);
        setEmployeesCountInPoll(employeesCountSelected);

        setClientsInPoll(allClientsSelected);
        setClientsCountInPoll(clientsCountSelected);

        setSelectedUsers(selectedUsers);
        setShowPollVisibilityModal(false);
    };

    if (loading) {
        return <LoadingForm />;
    }

    return (
        <PageContainer>
            <PageHeadingLabel size="2.5rem" margin="0 0 0 0">{ formTitle }</PageHeadingLabel>
            <div className="row" style={{ maxWidth: "37.5rem", marginTop: "13px", marginBottom: "33px" }}>
                <div className="description">
                    Easily set up a new poll to gather quick feedback or opinions. Customise your poll question, add answer options, and share it directly with your team or clients through the Me Business app. Get real-time results to make informed decisions.
                </div>
            </div>

            <FormBackground>
                <Form width="inherit" onSubmit={(e) => { e.preventDefault(); }} style={{ padding: "2.5rem 0" }} >
                    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-evenly" }}>
                        <div style={{ width: "45%" }}>
                            <FormSectionHeader style={{ fontWeight: "600" }}>
                                Poll Information
                            </FormSectionHeader>
                            <ImageUpload image={formImage} setFormImage={setFormImage} setImageChanged={setImageChanged} />
                            <PollNameField pollQuestion={pollQuestion} inputSurveyName={inputPollName} inputErrors={inputErrors} />
                            <PollDurationFields inputStartDate={inputStartDate} inputFinishDate={inputFinishDate} inputErrors={inputErrors} startsAt={startsAt} endsAt={endsAt} />
                            <PollResultVisibility
                                onToggle={togglePollResultsVisibility}
                                showSurveyResults={showSurveyResults}
                            />
                            <PollVisibility
                                togglePollVisibilityModal={togglePollVisibilityModal}
                                showPollVisibilityModal={showPollVisibilityModal}
                                handleSavePollVisibility={handleSavePollVisibility}
                                employeesInPoll={employeesInPoll}
                                clientsInPoll={clientsInPoll}
                                employeesCountInPoll={employeesCountInPoll}
                                clientsCountInPoll={clientsCountInPoll}
                                pollRespondentsUuids={pollParticipantUuids}
                                isEditForm={pollToEdit !== ""}
                                inputErrors={inputErrors} 
                            />
                        </div>
                        <div style={{ width: "45%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                            <div>
                                <SurveyQuestionsFields
                                    inputAnswerChange={inputAnswerChange}
                                    activeAnswerCount={activeAnswerCount}
                                    addAnswer={addAnswer}
                                    removeAnswer={removeAnswer}
                                    pollOptions={pollOptions}
                                    inputErrors={inputErrors}
                                    isEditForm={pollToEdit !== ""}
                                    isPollAnswersEditable={isPollAnswersEditable}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{ padding: "50px 3.75rem 22px 3.75rem" }}>
                        <hr />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", padding: "0 3.75rem" }}>
                        {
                            isFormSending ?
                                <LoadingIndicatorButton height="38px">
                                    <LoadingIndicatorIcon height="20px" width="20px" margin="5px" />
                                </LoadingIndicatorButton>
                                :
                                <>
                                    <CancelButton borderRadius="7px" onClick={(e) => handleCancelButton() }>Cancel</CancelButton>
                                    <SaveButton border="unset !important" color="white" backgroundColor="#006CFF" borderRadius="7px" onClick={(e) => { handleFormSubmission(); }}>Save</SaveButton>
                                </>
                        }
                    </div>
                </Form>
            </FormBackground>
        </PageContainer>
    );
};

export default PollsForm;