import React, { useState, useEffect } from "react";
import { Select, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { USER_TYPE_CLIENTS, USER_TYPE_EMPLOYEES } from "src/constants/chat";
import { FlexContainer, Text } from "src/components";

const useStyles = makeStyles(() => ({
    outlined: {
        "&:focus": {
            outline: "none",
            background: "none"
        },
        
    }
}));

const selectStyle = {
    height: "2.5625rem",
    fontFamily: "Roboto",
    background: "#FFFFFF",
    border: "1px solid #000000",
    paddingLeft: "0.5rem",
    borderRadius: 7,
    "&:focus": {
        backgroundColor: "transparent"
    },
    "&:hover": {
        backgroundColor: "transparent",
    },
};

const TypeSelection = (props) => {
    const classes = useStyles();
    const { defaultValue, changeSelectedType } = props;
    const { canSeeEmployeeList, canSeeClientList } = props;
    const [selectedOption, setSelectedOption] = useState(defaultValue);

    useEffect(() => {
        handleChangeUserType(defaultValue); 
    }, [defaultValue]);

    /**
    * @param {string} userListType    
    */
    const handleChangeUserType = (value) => {
        setSelectedOption(value);

        //fetch the user list via retrieveUpdatedUsersList()
        changeSelectedType(value); 
    };

    return (
        <FlexContainer>
            { props.showLabel &&
                <Text color="#4B5155" size="1rem" align="left" margin="0 0 0.8125rem 0" weight="700">
                    Select user list:
                </Text>
            }
            <Select value={selectedOption}
                variant="outlined"
                classes={{ outlined: classes.outlined }}
                onChange={e => handleChangeUserType(e.target.value)}
                style={{ 
                    ...selectStyle, 
                    width: props.width,
                    fontSize: props.fontSize || "1.125rem",
                    fontWeight: props.fontWeight || 600,
                    color: props.color || "#4B5155",
                }}
            >
                { canSeeEmployeeList && <MenuItem value={USER_TYPE_EMPLOYEES}>Employees</MenuItem> }
                { canSeeClientList && <MenuItem value={USER_TYPE_CLIENTS}>Clients</MenuItem> }
            </Select>
        </FlexContainer>
    );
};

export default TypeSelection;