import React, { createRef } from "react";

function AuthenticatedLink(props) {
    const { url, filename, user, children, ...styleProps } = props;
    const link = createRef();

    const handleAction = async (e) => {
        e.preventDefault();
        try {
            const result = await fetch(url, {
                headers: {
                    Authorization: "Bearer " + user.token,
                },
            });
            
            const blob = await result.blob();
            const href = window.URL.createObjectURL(blob);
            
            const tempLink = document.createElement("a");
            tempLink.download = filename;
            tempLink.href = href;

            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(href);
        } catch (error) {
            console.error("Error during download:", error);
        }
    };

    return (
        <>
            <a role="button" {...styleProps} ref={link} onClick={handleAction}>{children}</a>
        </>
    );
}

export default AuthenticatedLink;