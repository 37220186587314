import React, { useEffect } from "react";
import styled from "styled-components";
import WinningResult from "src/img/poll/winning_result";
import Lottie from "react-lottie-player";
import { useSpring, animated } from "@react-spring/web";

const ProgressBarWrapper = styled.div`
    width: 100%;
    border-radius: 15px;
    border: 1px solid #ddd;
    overflow: hidden;
`;

const AnimatedProgressBarFiller = styled(animated.div)`
    height: 57px;
    background: ${props => props.color || "#007bff"};
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border: 1px solid #fff;
`;


const CardContainer = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    border-radius: 15px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 5px #0000001A;
    border: ${p => p.borderColor || "1px solid #e0e0e0"};
    overflow: hidden;
    font-family: Roboto, Helvetica, sans-serif;
`;

const InfoContainer = styled.div`
    display: flex;
    position: absolute;
    align-items: center;
    flex: 1;
    padding-left: 15px;
    padding-right: 15px;
    justify-content: space-between;
    width: 95%;
`;

const Name = styled.div`
    font-weight: bold;
    font-size: 16px;
    color: ${p => p.textColor ?? "#333"};
`;

const Percentage = styled.div`
    font-size: 16px;
    font-weight: bold;
    color: ${p => p.textColor ?? "#333"};
    padding-right: 15px;
`;

const TopVoteCard = styled.div`
    position: absolute;
    width: 93px;
    height: 28px;
    background-color: #D7FFD1;
    border: 1px solid #36BE24;
    color: #36BE24;
    border-radius: 9px;
    display: flex;
    justify-content: center;
    align-content: center;
    z-index: 1;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    padding-top: 5.5px;
`;


const PollsResultsProgressBar = ({ value, name, percentage, containerBorderColor, barColor, textColor, isTopAnswer }) => {
    const [springStyles, api] = useSpring(() => ({
        width: "0%",
        config: { tension: 120, friction: 26.0 },
    }));

    const topVote = () => {
        return (
            <>
                <div style={{
                    width: "250px",
                    zIndex: "2",
                    display: "flex",
                    position: "absolute",
                    top: "-140px",
                    left: "50%",
                    transform: "translateX(-50%)",
                }}>
                    <Lottie
                        loop
                        play
                        animationData={WinningResult}
                        rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
                    />
                </div>
                <TopVoteCard>
                    Top Vote!
                </TopVoteCard>
            </>
        );
    };

    useEffect(() => {
        api.start({
            width: `${value}%`,
        });
    }, [value, api]);

    return (
        <div style={{ display: "flex", position: "relative" }}>
            { isTopAnswer && 
                topVote()
            }
            <CardContainer borderColor={containerBorderColor}>
                <ProgressBarWrapper>
                    <AnimatedProgressBarFiller
                        style={springStyles}
                        color={barColor || "linear-gradient(270deg, #F7F7F7 0%, #DDDDDD 100%)"}
                    />
                </ProgressBarWrapper>
                <InfoContainer>
                    <Name textColor={textColor} >{name}</Name>
                    <Percentage textColor={textColor} >{percentage}%</Percentage>
                </InfoContainer>
            </CardContainer>
        </div>
    );
};

export default PollsResultsProgressBar;