import React from "react";
import { AnswerContainer } from "../../components/Utils";

const DynamicPollAnswer = (props) => {
    const {
        inputAnswerChange, pollOptions,
        pollOptionIndex, activeAnswerCount,
        answerIndex, removeAnswer, disabled,
        isEditForm
    } = props;

    return (
        <>
            {activeAnswerCount >= answerIndex &&
                <div style={{ display: "flex", direction: "row", position: "relative" }}>
                    <div style={{ display: "block", width: "100%" }}>
                        <AnswerContainer
                            answerNumber={answerIndex}
                            answerValue={pollOptions[pollOptionIndex].value}
                            inputAnswerChange={inputAnswerChange}
                            pollOptions={pollOptions}
                            disabled={disabled}
                            isEditForm={isEditForm}
                            removeAnswer={removeAnswer}
                            answerIndex={answerIndex}
                            showRemoveQuestion={activeAnswerCount === answerIndex && !disabled}
                        />
                    </div>
                </div>
            }
        </>
    );
};

export default DynamicPollAnswer;