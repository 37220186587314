import React, { useState, useEffect } from "react";
import { Button, Text } from "src/components";
import { Dialog, Box, Grid, Divider } from "@material-ui/core";
import styled from "styled-components";
import "react-datepicker/dist/react-datepicker.css";
import getPerkInsights from "../hooks/getPerkInsights";
import { FlexContainer, LottieAnimation } from "src/components";
import PurpleLottieJson from "src/scenes/Noticeboard/assets/purple-lottie.json";
import DropdownIcon from "src/img/new/drop-down.svg";
import { generateNameInitials } from "src/utils/helpers";

const SelectFilter = styled.select`
    height: 2.438rem;
    width: 11.125rem;
    border-radius: 7px;
    background: #fff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #000;
    padding: 0.3em 1.1em;
    color: #000;
    font-size: 0.875rem;
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: 700;
    background-image: url(${DropdownIcon});
    background-repeat: no-repeat;
    background-position-x: 97%;
    background-position-y: 50%;
    appearance: none;

    &:focus {
        outline: none;
    }
`;

const TextContainer = styled.div`
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: ${p => p.justifyContent || "center"};
    align-items: ${p => p.alignItems || "start"};
`;

const FilterType = {
    EMPLOYEES: "employees",
    CLIENTS: "clients"
};

const InsightModal = ({
    user,
    perk,
    showInsightModal,
    handleCloseInsightModal
}) => {
    const { perkInsights, isFetchingInsights } = getPerkInsights(user.token, perk.uuid);
    const [clickCount, setClickCount] = useState(0);
    const [filterType, setFilterType] = useState(FilterType.EMPLOYEES);

    const filteredData = () => {
        if (!perkInsights) return [];
        return perkInsights.filter(insight =>
            filterType === FilterType.EMPLOYEES ? !insight.isClient : insight.isClient
        );
    };

    useEffect(() => {
        const data = filteredData();
        const totalClicks = data.reduce((sum, insight) => sum + insight.noOfClicks, 0);
        setClickCount(totalClicks);
    }, [filterType, perkInsights]);

    const handleFilterChange = (e) => {
        setFilterType(e.target.value);
    };

    return (
        <Dialog
            open={showInsightModal}
            maxWidth="md"
            scroll="body"
            PaperProps={{
                style: {
                    width: 900,
                    height: "auto",
                    borderRadius: 15,
                    overflow: "auto"
                }
            }}
        >
            <Box sx={{ padding: "1.563rem 3.125rem 1rem" }}>
                <Grid container>
                    <Grid item md={6}>
                        <Text size="2.313rem" weight="700" align="left" margin="0">
                            Link Clicks
                        </Text>
                    </Grid>
                    <Grid item md={6} style={{ textAlign: "right" }}>
                        { !isFetchingInsights && (
                            <SelectFilter value={filterType} onChange={handleFilterChange}>
                                <option value={FilterType.EMPLOYEES}>Employees</option>
                                <option value={FilterType.CLIENTS}>Clients</option>
                            </SelectFilter>
                        )}
                    </Grid>
                </Grid>
            </Box>
            <Divider />
            <Box sx={{ padding: "1.25rem 3.125rem" }}>
                <Grid container>
                    <Grid item md={7}>
                        <TextContainer>
                            <h1 style={{ fontSize: "1.25rem", fontWeight: "700", margin: "0" }}>Perk Engagement Stats</h1>
                            <p style={{ fontSize: "1.rem", fontWeight: "normal", margin: "10px 0 0 0" }}>
                                Track the performance of your Perks by viewing the number <br />
                                of link clicks each Perk has received.
                            </p>
                        </TextContainer>
                    </Grid>

                    <Grid item md={5}>
                        <TextContainer justifyContent="end" alignItems="end">
                            {!isFetchingInsights && (
                                <h1 style={{ fontSize: "2.5rem", fontWeight: "700", margin: "0" }}>{clickCount} {clickCount === 1 ? "Click" : "Clicks"}</h1>
                            )}
                        </TextContainer>
                    </Grid>
                </Grid>
            </Box>
            <Divider />
            <Box sx={{ padding: "0.625rem 3.125rem 3rem" }}>
                {isFetchingInsights && (
                    <FlexContainer
                        justifyContent="center"
                        alignItems="center"
                        height="25rem"
                    >
                        <LottieAnimation animationJsonData={PurpleLottieJson} margin="0" />
                    </FlexContainer>
                )}

                {!isFetchingInsights && (
                    <ViewersListPreview viewersList={filteredData()} isReaderTypeEmployee={filterType === FilterType.EMPLOYEES} />
                )}
            </Box>
            <Divider />
            <Box sx={{ padding: "1.25rem 3.125rem 2.188rem" }} className="text-right">
                <Button
                    onClick={handleCloseInsightModal}
                    size="0.875rem"
                    border="1px solid #000"
                    color="#000"
                    width="8.75rem"
                    style={{ borderRadius: "7px", boxShadow: "none" }}
                >
                    Close
                </Button>
            </Box>
        </Dialog>
    );
};

const ContainerOverflow = styled.div`
    height: 25.938rem;
    overflow-y: scroll;
    padding-right: 1em;

    ::-webkit-scrollbar {
        display: block;
        width: 8px;
        height: 8px;
    }

    ::-webkit-scrollbar-track {
        background: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #174A84;
        border-radius: 6px;
        border-right: none;
        border-left: none;
    }

    ::-webkit-scrollbar-track-piece:end {
        background-color: #D1DBE6;
        border-radius: 6px;
        margin-right: 30px;
    }

    ::-webkit-scrollbar-track-piece:start {
        background-color: #D1DBE6;
        border-radius: 6px;
    }
`;

const TablePreview = styled.div`
    .thead * {
        font-weight: 700;
    }
    .tbody * {
        font-weight: 400;
    }
    .tbody, .thead{
        display: table;
        width: 100%;
    }

    .tbody > *, .thead > * {
        display: table-row;
    }

    .tbody .col, .thead .col {
        display: table-cell;
        padding: 12px 16px 12px 16px;
        width: calc(100% / 4); /* Assuming 3 columns */
    }

    .col-fullname-width {
        width: calc(100% / 2) !important;
    }

    .tbody .col {
        border-left: 1px solid #8291B2;
        border-top: 1px solid #8291B2;
    }

    .tbody .lastcol {
        border-right: 1px solid #8291B2;
    }

    .tbody .lastrow > .col {
        border-bottom: 1px solid #8291B2;
    }
`;

const CircledInitials = styled.div`
    font-family: SofiaPro;
    font-size: 16px;
    font-weight: 600;
    width: 42px;
    height: 42px;
    background-color: #D6D6D6;
    color: #6F6F6F;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5em;
`;

const UserRow = ({ index, maxIndex, uuid, nameInitials, fullName, department, jobTitle, isReaderTypeEmployee }) => {
    const isFirstRow = index === 0;
    const isLastRow = index === maxIndex;
    const isOnlyOneRow = isFirstRow && isLastRow;

    const topLeftRadius = "8px 0px 0px 0px";
    const topRightRadius = "0px 8px 0px 0px";
    const bottomLeftRadius = "0px 0px 0px 8px";
    const bottomRightRadius = "0px 0px 8px 0px";

    let divStyleFirstColumn = isFirstRow ? { borderRadius: topLeftRadius } : isLastRow ? { borderRadius: bottomLeftRadius } : {};
    let divStyleLastColumn = isFirstRow ? { borderRadius: topRightRadius } : isLastRow ? { borderRadius: bottomRightRadius } : {};

    if (isOnlyOneRow) {
        divStyleFirstColumn = { borderRadius: "8px 0px 0px 8px" };
        divStyleLastColumn = { borderRadius: "0px 8px 8px 0px" };
    }

    return (
        <div className={`row ${isLastRow ? "lastrow" : ""}`} key={uuid + "_" + index}>
            <div className="col col-fullname-width" style={ divStyleFirstColumn }>
                <div style={{ display: "inline-block" }}><CircledInitials>{nameInitials}</CircledInitials></div> {fullName}
            </div>
            {isReaderTypeEmployee && <div className="col">{department}</div>}
            <div className={"col lastcol"} style={ divStyleLastColumn }>{jobTitle}</div>
        </div>
    );
};

const ViewersListPreview = ({ viewersList, isReaderTypeEmployee }) => {

    if (!viewersList || viewersList.length === 0) {
        return ("No user clicks found");
    }

    return (
        <TablePreview>
            <div className="thead">
                <div className="row" key="theadrow">
                    <div className="col col-fullname-width">{isReaderTypeEmployee ? "Employee" : "Client" } Name</div>
                    {isReaderTypeEmployee && <div className="col">Department / Group</div>}
                    <div className="col">Job Title</div>
                </div>
            </div>

            <ContainerOverflow>
                <div className="tbody">
                    {
                        viewersList.map((employee, index) => {
                            const nameInitials = generateNameInitials(employee.firstName, employee.lastName);
                            const fullName = employee.firstName + " " + employee.lastName;
                            return (
                                <UserRow
                                    key={fullName}
                                    index={index}
                                    maxIndex={viewersList.length - 1}
                                    uuid={employee.uuid}
                                    nameInitials={nameInitials}
                                    fullName={fullName}
                                    department={employee.department}
                                    jobTitle={employee.position}
                                    isReaderTypeEmployee={isReaderTypeEmployee}
                                />
                            );
                        })
                    }
                </div>
            </ContainerOverflow>
        </TablePreview>
    );
};

export default InsightModal;