import { ClientEvent, EventType, UserEvent } from "matrix-js-sdk";
import { useCallback, useEffect, useState } from "react";
import { API_URL } from "src/scenes/App";
import axios from "axios";

/**
 * as of writing, other `presence` properties have no usage so we will only store the uuids of online users in an array
 * @param {MatrixClient} matrixClient 
 * @param {boolean} isInitialSyncComplete
 * @param {Object} headers
 * @param {Object} currentUser
 * @param {(string) => void} retrieveUsersList
 * @param {string} selectedChatListTypeOrLeadershipUuid
 * @param {boolean} didFetchChatsFail
 * @returns {{
 *      onlineUsers: string[]
 *      currentUserOnlineStatus: boolean
 * }} - online users' Matrix IDs, and whether the current user is online
 */

export const usePresenceListener = (matrixClient, isInitialSyncComplete, headers, currentUser, retrieveUsersList, selectedChatListTypeOrLeadershipUuid, didFetchChatsFail) => {
    const [ onlineUsersMatrixId, setOnlineUsersMatrixId ] = useState([]); // see JSDoc above

    useEffect(() => {
        if (matrixClient && isInitialSyncComplete) {
            listenForUserPresence(matrixClient);
        }
    }, [matrixClient, isInitialSyncComplete]);

    const listenForUserPresence = async (client) => {
        /**
         * this listens only to presence events manually sent by a user (ie: opening chat or closing, setting status manually)
         * but this cannot get the presence of other users that are already online when user opens the chat
         * use this if you want to show the user as `offline/online` right after their presence changes
         */
        client.on(UserEvent.Presence, (event) => {
            presenceHandler(event, "setOwnPresence");
        });

        /**
         * This can listen to Presence events regularly sent at fixed intervals (20-30 seconds),
         * allowing us to get the presence of other users that are already online
         */
        client.on(ClientEvent.Event, (event) => {
            presenceHandler(event, "sync");
        });
    };

    /**
     * This function is called by default for handling changes sent from matrix sync statuses
     * this allows us to be able to switch the status dot for the chat list and create chat modal list
     */
    const presenceHandler = (event, listenerType) => {
        if (listenerType === "setOwnPresence" && event.getType() === EventType.Presence) {
            const presenceEvent = event.getEffectiveEvent();
            if (!presenceEvent) return;
            const presence = presenceEvent.content.presence;
            const senderUserId = presenceEvent.sender;
            handleStatus(presence, onlineUsersMatrixId, senderUserId);
        }
        /* this section will make sure we're only monitoring statuses of other users
        ** doing so will prevent unintended overrides in the current user side of things
        */
        if (listenerType === "sync" && event.getType() === EventType.Presence) {
            const presenceEvent = event.getEffectiveEvent();
            if (!presenceEvent) return;
            const presence = presenceEvent.content.presence;
            const senderUserId = presenceEvent.sender;
            if (currentUser.matrixUserId !== senderUserId) {
                handleStatus(presence, onlineUsersMatrixId, senderUserId);
            }
            if (!didFetchChatsFail) {
                /*
                * temporarily commented this user updating behavior in order to improve performance
                * will probably work on finding a different way to handle this gracefully without having
                * the server request numerous times in order to improve loading performance
                */
                // retrieveUsersList(selectedChatListTypeOrLeadershipUuid);
            }
        }
    };

    const handleStatus = (status, onlineUsers, matrixUserId) => {
        if (status === "offline") {
            const userIds = handleOfflinePresence(onlineUsers, matrixUserId);
            setOnlineUsersMatrixId(userIds);
        }
        else if (status === "online") {
            const userIds = handleOnlinePresence(onlineUsers, matrixUserId);
            setOnlineUsersMatrixId(userIds);
        }
    };

    /*
    **  setUserPresence is only called for the dropdown found in chatList.js
    **   as a onClick action passed down to UserAvatar.js
    */
    const setUserPresence = useCallback(async (onlineUsers, status, matrixUserId, handleSettingCurrentUserStatus, currentUserState) => {
        handleStatus(status, onlineUsers, matrixUserId);

        try {
            if (!matrixUserId) {
                throw new Error("matrixUserId not found");
            }
            
            const presenceOpts = {
                presence: status// at the next sync, user's presence automatically becomes `online`
            };
            const url = `${API_URL}/_matrix/client/v3/presence/${matrixUserId}/status`;
            const result = await axios.put(url, presenceOpts, headers);

            if (result.status === 200) {
                /*
                **  this part will send the passed status towards our backend
                **  status depends on which the user set themselves as,
                **   options can be found in UserAvatar.js under showUserStatusDropdown options
                */
                const formData = {
                    chatPresenceOverride: status,
                    matrixUserId: matrixUserId
                };
                const savePresenceUrl = `${API_URL}/chats/users/setPresenceOverride`;
                try {
                    const result = await axios.post(savePresenceUrl, formData, headers);
                    handleStatus(status, onlineUsersMatrixId, matrixUserId);
                    if (result.status === 200) {
                        handleSettingCurrentUserStatus(status);
                    }
                } catch (error) {
                    return console.error("Failed saving user status.", error);
                }
            }
        } catch (error) {
            return console.error("Failed reaching matrix servers.", error);
        }
    });

    return {
        onlineUsersMatrixId,
        setUserPresence
    };
};

const getIndexFromOnlineUsersList = (onlineUsers, senderId) => {
    if (Array.isArray(onlineUsers)) {
        return onlineUsers.findIndex(userId => userId === senderId);
    }
};

const handleOnlinePresence = (matrixUserIds, matrixUserIdToAdd) => {
    const index = getIndexFromOnlineUsersList(matrixUserIds, matrixUserIdToAdd);
    if (index === -1) {
        const newIds = [...matrixUserIds];
        newIds.push(matrixUserIdToAdd);
        return newIds;
    }
    return matrixUserIds;
};

const handleOfflinePresence = (matrixUserIds, matrixUserIdToRemove) => {
    const index = getIndexFromOnlineUsersList(matrixUserIds, matrixUserIdToRemove);
    if (index !== -1) {
        const newIds = [...matrixUserIds];
        newIds.splice(index, 1);
        return newIds;
    }
    return matrixUserIds;
};