import React, { useState, useEffect, useContext, useRef } from "react";
import styled from "styled-components";
import { Box, Dialog } from "@material-ui/core/";
import { FlexContainer, Text } from "src/components";
import UsersIcon from "src/img/survey/updatedIcons/users.svg";
import { UserContext } from "src/scenes/App/UserContext";
import { UserKeys } from "src/constants/userDetails";
import axios from "axios";
import { API_URL } from "src/scenes/App";
import { CancelButton } from "./Utils";
import Skeleton from "src/scenes/Statistics/components/Skeleton";
import moment from "moment";
import PollsResultsProgressBar from "./PollsResultsProgressBar";
import Confetti from "src/img/poll/confetti_background.json";
import Lottie from "react-lottie-player";
import { SurveyStatusType } from "src/constants/survey";
import { RealTimeBadge } from "./RealTimeBadge";

const Header = styled(FlexContainer)`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #F4F5F8;
    height: 5.125rem;
    padding: 0 2.375rem 0 1.6875rem;
    border-bottom: 1px solid #DBE5ED;
    border-radius: 1.25rem 1.25rem 0 0;
`;

const TimerCard = styled.div`
    width: 50px;
    height: 50px;
    background: rgba(255,255,255,0.5);
    filter: blur(5);
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const TimerTime = styled.div`
    width: auto;
    text-align: center;
    color: #000;
    font-weight: 700;
    font-size: 20px;
    font-family: Roboto, Helvetica, sans-serif;
`;

const TimerText = styled.div`
    width: auto;
    text-align: center;
    color: #000;
    font-size: 10px;
    font-family: Roboto, Helvetica, sans-serif;
    text-transform: uppercase;
`;

const PollResultsModal = (props) => {
    const currentUser = useContext(UserContext);
    const controllerRef = useRef(new AbortController());

    const {
        showPollResults,
        onToggle,
        pollUuid
    } = props;

    const [loading, setLoading] = useState(true);
    const [pollDetails, setPollDetails] = useState([]);
    const [isPollComplete, setIsPollComplete] = useState(false);

    useEffect(() => {
        if (pollUuid !== "") {
            getPoll(pollUuid);
        }
    }, [pollUuid]);

    useEffect(() => {
        if (pollDetails && pollDetails.status === SurveyStatusType.COMPLETE) {
            setIsPollComplete(true);
        } else {
            setIsPollComplete(false);
        }

        if (pollDetails && pollDetails.status === SurveyStatusType.ACTIVE) {
            const interval = setInterval(getPollRealTime(pollUuid), 10000);
            return () => clearInterval(interval);
        }

    }, [pollDetails]);

    /** to retrieve a specific poll, used in edit poll view */
    const getPoll = (pollUuid) => {
        if (controllerRef.current) {
            controllerRef.current.abort();
        }

        controllerRef.current = new AbortController();
        setLoading(true);
        let URL = `${API_URL}/polls/companyPolls/${pollUuid}`;
        
        axios.get(URL, {
            headers: { Authorization: `Bearer ${currentUser[UserKeys.TOKEN]}` },
            signal: controllerRef.current.signal
        }).then(res => {
            const pollData = res.data.poll;
            setPollDetails(pollData);
        }).catch(error => {
            if (axios.isCancel(error)) {
                console.error(error.message || error.response.data.error);
            }
        }).finally(res => {
            setLoading(false);
        });
    };

    const getPollRealTime = (pollUuid) => {
        if (controllerRef.current) {
            controllerRef.current.abort();
        }

        controllerRef.current = new AbortController();
        let URL = `${API_URL}/polls/companyPolls/${pollUuid}`;
        
        axios.get(URL, {
            headers: { Authorization: `Bearer ${currentUser[UserKeys.TOKEN]}` },
            signal: controllerRef.current.signal
        }).then(res => {
            const pollData = res.data.poll;
            setPollDetails(pollData);
        }).catch(error => {
            if (axios.isCancel(error)) {
                console.error(error.message || error.response.data.error);
            }
        });
    };

    const renderPollAnswerResults = (pollAnswers, isLoading) => {
        if (isLoading) {
            return (
                <>
                    <div style={{ marginBottom: "10px", borderRadius: "15px" }}>
                        <Skeleton width="auto" height="60px" animation="wave" />
                    </div>
                    <div style={{ marginBottom: "10px", borderRadius: "15px" }}>
                        <Skeleton width="auto" height="60px" animation="wave" />
                    </div>
                    <div style={{ marginBottom: "10px", borderRadius: "15px" }}>
                        <Skeleton width="auto" height="60px" animation="wave" />
                    </div>
                </>
            );
        }

        if (!pollAnswers) {
            return (
                <Text size="20px" weight="700" align="left" style={{ marginBottom: "20px", marginTop: "0" }} >
                    No Responses found.
                </Text>
            );
        }

        return pollAnswers.map((pollAnswerItem) => {
            return (
                <div style={{ marginBottom: "10px" }}>
                    <PollsResultsProgressBar value={pollAnswerItem["percentage"]} name={pollAnswerItem["value"]} percentage={pollAnswerItem["percentage"]} />
                </div>
            );
        });
    };

    const renderPollCompleteAnswerResults = (pollAnswers, isLoading) => {
        if (isLoading) {
            return (
                <>
                    <div style={{ marginBottom: "10px", borderRadius: "15px" }}>
                        <Skeleton width="auto" height="60px" animation="wave" />
                    </div>
                    <div style={{ marginBottom: "10px", borderRadius: "15px" }}>
                        <Skeleton width="auto" height="60px" animation="wave" />
                    </div>
                    <div style={{ marginBottom: "10px", borderRadius: "15px" }}>
                        <Skeleton width="auto" height="60px" animation="wave" />
                    </div>
                </>
            );
        }
        const topAnswer = pollAnswers.find((item) => item.isTopVote) || {};
        const otherAnswers = pollAnswers.filter((item) => !item.isTopVote);
        return (
            <>
                <PollsResultsProgressBar
                    value={topAnswer["percentage"] * 100}
                    name={topAnswer["value"]}
                    percentage={topAnswer["percentage"]}
                    containerBorderColor="1px solid #1A61FF"
                    barColor="linear-gradient(270deg, #EDF4FF 0%, #D6E3FF 100%)"
                    textColor="#1A61FF"
                    isTopAnswer={true}
                />
                <div style={{ paddingBottom: "12.5px", paddingTop: "12.4px" }}>
                    <hr/>
                </div>
                <FlexContainer
                    direction="column"
                    mDirection="column"
                    tDirection="column"
                    justifyContent="flex-start"
                    width="100%"
                    tWidth="100%"
                    mWidth="100%"
                    overflowY="auto"
                    maxHeight="250px"
                >
                    {
                        otherAnswers.map((pollAnswerItem) => 
                            (
                                <div style={{ marginBottom: "10px" }}>
                                    <PollsResultsProgressBar value={pollAnswerItem["percentage"]} name={pollAnswerItem["value"]} percentage={pollAnswerItem["percentage"]} />
                                </div>
                            ))
                    }
                </FlexContainer>
            </>
        );
    };

    const renderPollTimer = (endsAt, isLoading) => {
        const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

        useEffect(() => {
            const interval = setInterval(() => {
                const targetDate = moment(endsAt);
                const now = moment();
                const duration = moment.duration(targetDate.diff(now));
    
                if (duration.asSeconds() <= 0) {
                    clearInterval(interval);
                    setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
                } else {
                    setTimeLeft({
                        days: Math.floor(duration.asDays()),
                        hours: duration.hours(),
                        minutes: duration.minutes(),
                        seconds: duration.seconds(),
                    });
                }
            }, 1000);
    
            return () => clearInterval(interval);
        }, [endsAt]);
        if (isLoading) {
            return (
                <FlexContainer
                    mDirection="row"
                    tDirection="row"
                    direction="row"
                    justifyContent="space-between"
                    width="25%"
                    tWidth="50%"
                    mWidth="60%"
                    marginBottom="14px"
                >
                    {[...Array(4)].map((_, index) => (
                        <TimerCard key={index}>
                            <Skeleton animation="wave" height="auto" width="auto" />
                        </TimerCard>
                    ))}
                </FlexContainer>
            );
        }

        return (
            <FlexContainer
                mDirection="row"
                tDirection="row"
                direction="row"
                justifyContent="space-between"
                width="25%"
                tWidth="50%"
                mWidth="60%"
                marginBottom="14px"
            >
                <TimerCard>
                    <div style={{ height: "auto", width: "auto" }}>
                        <TimerTime>{String(timeLeft.days).padStart(2, "0")}</TimerTime>
                        <TimerText>Days</TimerText>
                    </div>
                </TimerCard>
                <TimerCard>
                    <div style={{ height: "auto", width: "auto" }}>
                        <TimerTime>{String(timeLeft.hours).padStart(2, "0")}</TimerTime>
                        <TimerText>Hours</TimerText>
                    </div>
                </TimerCard>
                <TimerCard>
                    <div style={{ height: "auto", width: "auto" }}>
                        <TimerTime>{String(timeLeft.minutes).padStart(2, "0")}</TimerTime>
                        <TimerText>Min</TimerText>
                    </div>
                </TimerCard>
                <TimerCard>
                    <div style={{ height: "auto", width: "auto" }}>
                        <TimerTime>{String(timeLeft.seconds).padStart(2, "0")}</TimerTime>
                        <TimerText>Sec</TimerText>
                    </div>
                </TimerCard>
            </FlexContainer>
        );
    };

    const confetti = () => {
        return (
            <div style={{
                width: "100%",
                zIndex: "20",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                pointerEvents: "none",
            }}>
                <Lottie
                    loop
                    play
                    animationData={Confetti}
                    rendererSettings={{ preserveAspectRatio: "xMidYMax meet" }}
                />
            </div>
        );
    };

    const checkIfTheresQuestions = (question) => {
        return question && question.length > 0;
    };

    const handleCloseModal = () => {
        onToggle();
        setIsPollComplete(false);
    };

    return (
        <React.Fragment>
            <Dialog open={showPollResults} onClose={handleCloseModal}
                maxWidth="md"
                scroll="body"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                PaperProps={{
                    style: {
                        width: "90%",
                        height: "fit-content",
                        maxHeight: "900px",
                        borderRadius: "1.25rem",
                        overflow: "auto",
                        background: "#F9FAFC"
                    },
                }}
                className="scroll-design"
            >
                <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                    <Header>
                        <Text color="#4B5155" size="18px" weight="700">Poll Results</Text>
                        {pollDetails.status === SurveyStatusType.ACTIVE && <div style={{ justifyItems: "flex-end", alignItems: "center" }}>
                            <RealTimeBadge />
                        </div>}
                    </Header>
                    <Box sx={{ flex: "1" }}>
                        <FlexContainer style={{ height: "100%", }}>
                            <FlexContainer position="relative" >
                                {
                                    loading ?
                                        <Skeleton variant="rect" width="100%" height="269px" animation="wave" />
                                        :
                                        <img src={pollDetails.image.imageUrl} height="269px" width="100%" style={{ objectFit: "cover", objectPosition: "center" }} />
                                }
                                <FlexContainer
                                    mDirection="row"
                                    tDirection="row"
                                    direction="row"
                                    position="absolute"
                                    bottom="0"
                                    justifyContent="center"
                                    width="100%"
                                    tWidth="100%"
                                    mWidth="100%"
                                >
                                    { renderPollTimer(pollDetails.endDate, loading) }
                                </FlexContainer>
                            </FlexContainer>
                            <FlexContainer
                                direction="row"
                                mDirection="column"
                                tDirection="row"
                                margin="50px"
                                marginBottom="30px"
                                height="fit-content"
                                mWidth="auto"
                                tWidth="auto"
                            >
                                <FlexContainer direction="column" mDirection="column" tDirection="column" justifyContent="left" width="100%" mWidth="100%" tWidth="100%">
                                    <Text size="20px" weight="700" align="left" style={{ marginBottom: "20px", marginTop: "0" }} >
                                        { 
                                            loading ? 
                                                <Skeleton width="300px" height="26px" animation="wave" /> 
                                                : 
                                                <>
                                                    { isPollComplete 
                                                        ? <span style={{ color: "#70cf64" }}>Poll Complete!</span>
                                                        : `Ends ${moment(pollDetails.endsAt).format("MMM Do hh:mm a")}`}
                                                </>
                                        }
                                    </Text>
                                    <Text size="14px" weight="700" align="left" style={{ marginBottom: "15px", marginTop: "0", display: "flex" }} >
                                        {
                                            loading ?
                                                <Skeleton width="300px" height="26px" animation="wave" />
                                                :
                                                <>
                                                    <img src={UsersIcon} height="20px" style={{ marginRight: "5px" }} />
                                                    {pollDetails.totalVotes} Votes
                                                </>
                                        }
                                    </Text>
                                    <Text size="20px" weight="700" align="left" style={{ marginBottom: "15px", marginTop: "0" }} >
                                        { loading ? 
                                            <Skeleton width="300px" height="26px" animation="wave" />
                                            :
                                            pollDetails.question
                                        }
                                    </Text>
                                </FlexContainer>
                                <FlexContainer
                                    direction={loading ? "column" : checkIfTheresQuestions(pollDetails.question) ? "column" : "row" }
                                    mDirection={loading ? "column" : checkIfTheresQuestions(pollDetails.question) ? "column" : "row" }
                                    tDirection={loading ? "column" : checkIfTheresQuestions(pollDetails.question) ? "column" : "row" }
                                    justifyContent="flex-start"
                                    width="100%"
                                    tWidth="100%"
                                    mWidth="100%"
                                    overflowY={loading ? "auto" : isPollComplete ? "none" : "auto"}
                                    maxHeight="355px"
                                >
                                    {
                                        isPollComplete ?
                                            renderPollCompleteAnswerResults(pollDetails.options, loading)
                                            :
                                            renderPollAnswerResults(pollDetails.options, loading) 
                                    }
                                </FlexContainer>
                            </FlexContainer>
                            <div style={{ paddingBottom: "10px" }}>
                                <hr/>
                            </div>
                            <FlexContainer direction="row" mDirection="row" tDirection="row" justifyContent="center" marginBottom="35px" >
                                <CancelButton borderRadius="7px" onClick={handleCloseModal}>Close</CancelButton>
                            </FlexContainer>
                        </FlexContainer>
                    </Box>
                </div>
                { isPollComplete && confetti() }
            </Dialog>
        </React.Fragment>
    );
};

export default PollResultsModal;