import React, { useContext, useEffect, useState } from "react";
import { Box, Divider, Select, MenuItem } from "@material-ui/core/";
import { ChatContext } from "../ChatContext";
import { makeStyles } from "@material-ui/core/styles";
import { LoadingIndicator } from "src/components";
import IconButton from "./IconButton";
import UserAvatar from "./UserAvatar";

import UserSingleIcon from "src/img/new/new-1-on-1-chat.svg";
import UserGroupIcon from "src/img/new/new-group-chat.svg";
import SettingsIcon from "src/img/new/profile-settings.svg";
import { 
    StyledText, CompanyLogo,
    Container, StyledInput,
    CompanyLogoContainer,
    Icon, CreateChatButtons,
} from "./styled/chatListStyled";

import { GROUP_CHAT, ONE_ON_ONE_CHAT, USER_TYPE_CLIENTS, USER_TYPE_EMPLOYEES } from "src/constants/chat";
import { CREATE_GROUP_CHAT, FULL_ACCESS_BUSINESS_CHAT, MESSAGE_CLIENTS, MESSAGE_EMPLOYEES, isChatActionAllowed } from "src/constants/permissions";
import { UserContext } from "src/scenes/App/UserContext";
import { UserKeys } from "src/constants/userDetails";
import ListOfChats from "./children/ListOfChats";
import UnreadCount from "./children/UnreadCount";
import { usePresenceListener } from "../hooks";
import styled from "styled-components";
import { checkIfUserIsOnline } from "src/utils/helpers";
import { downloadUserKeysForDecryption } from "src/utils/helpers";

const useStyles = makeStyles(() => ({
    outlined: {
        "&:focus": {
            outline: "none",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
        },
    },
    listItemSecondary: {
        color: "#000"
    }
}));

const StyledMenuItem = styled(MenuItem)`
    [class*='UnreadCount'] {
        position: absolute;
        right: 15px;
    }
`;

const ActiveChatListSelect = styled(Select)`
    .MuiSelect-selectMenu{
        display: flex;
    }

    [class*='UnreadCount'] {
        position: absolute;
        right: 30px;
    }
`;

const selectStyle = {
    width: 227,
    height: 40,
    fontSize: "0.9rem",
    fontFamily: "Roboto",
    fontWeight: "600",
    color: "#4B5155",
    border: "1px solid #DBE5ED",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: 7,
    "&:focus": {
        backgroundColor: "transparent"
    }
};


const ChatList = (props) => {
    const {
        businessBackgroundColor,
        matrixRoomIdToRemove,
        isCurrentlyCreatingChat
    } = props;
    const currentUser = useContext(UserContext);
    const {
        showLoadingState,
        showCreateChatModalOrShowLeadershipEmployees,
        selectedChatListTypeOrLeadershipUuid,
        handleChatListSelectChange,
        displayProfileModal,
        leadershipRoles,
        handleShowBackupKeyModal,
        newTimelineEvent,
        matrixClient,
        handleOpenChat,
        onlineUsersMatrixId,
        isInitialSyncComplete,
        handleSettingCurrentUserStatus,
        userChatsList,
        setChatList,
        isUpdatingChatlist,
        activeChatListDetails,
        activeChatsTotalUnread,
        updateListOfChats,
        isFetchingChats
    } = useContext(ChatContext);
    const classes = useStyles();
    const [searchedChatList, setSearchedChatList] = useState(userChatsList);
    const [isChatListSearched, setIsChatListSearched] = useState(false);
    const headers = { headers: { Authorization: "Bearer " + currentUser[UserKeys.TOKEN] } };
    const { setUserPresence } = usePresenceListener(matrixClient, isInitialSyncComplete, headers);
    const PRESENCE_STATUS = { ONLINE: "online", OFFLINE: "offline" };
    const currentUserIsNewAndPresenceIsUnsetOrOverrrideOnline = currentUser[UserKeys.CHAT_PRESENCE_OVERRIDE] === "" ? true : currentUser[UserKeys.CHAT_PRESENCE_OVERRIDE] === PRESENCE_STATUS.ONLINE;
    const amIOnline = currentUser[UserKeys.CHAT_PRESENCE_OVERRIDE] === PRESENCE_STATUS.OFFLINE ? false : currentUserIsNewAndPresenceIsUnsetOrOverrrideOnline || checkIfUserIsOnline(onlineUsersMatrixId, matrixClient.getUserId());

    useEffect(() => {
        const processReceivedEvent = async () => {
            const roomId = newTimelineEvent.getRoomId();
            const newEventIsFromChatListRoom = userChatsList.find((room) => room.matrixRoomId === roomId);
            
            let existingChats = [...userChatsList];

            if (!newEventIsFromChatListRoom) {
                await updateListOfChats(selectedChatListTypeOrLeadershipUuid);
                existingChats = [...userChatsList];
            }

            if (roomId) {

                if (newTimelineEvent.shouldAttemptDecryption()) {
                    const room = matrixClient.getRoom(roomId);
                    await downloadUserKeysForDecryption(matrixClient, room);
                }

                if (!newTimelineEvent.shouldAttemptDecryption()) {
                    await newTimelineEvent.getDecryptionPromise();
                }

                const clientUserId = matrixClient.getUserId();
                
                let index = existingChats.findIndex(chat => chat.matrixRoomId === roomId);
                
                if (index === -1) { //the chatList state when empty before having a new one, 
                    console.warn("Room has not been found from the active list", newEventIsFromChatListRoom, existingChats);
                    return;
                }
                const targetChat = existingChats.splice(index, 1)[0];
                targetChat.recentMessage = newTimelineEvent.getContent().body;
                targetChat.latestEvent = newTimelineEvent; //TODO: create another object for custom field
                targetChat.unreadCount = clientUserId !== newTimelineEvent.getSender() ? targetChat.unreadCount++ : targetChat.unreadCount;

                existingChats.unshift(targetChat);
                setChatList(existingChats);
            }
        };

        if (newTimelineEvent && Array.isArray(userChatsList)) {
            try {
                processReceivedEvent();
            }
            catch (e) {
                console.warn("Error in rendering Chatlist. ", e);
            }
        }
    }, [newTimelineEvent]);

    useEffect(() => {
        if (matrixRoomIdToRemove !== null && matrixRoomIdToRemove !== undefined) {
            const index = userChatsList.findIndex(chat => chat.matrixRoomId === matrixRoomIdToRemove);
            if (index === -1) {
                console.error("Matrix room to remove does not exist in this user's chats list", matrixRoomIdToRemove);
                return;
            }
            
            const list = [...userChatsList];
            list.splice(index, 1);
            setChatList(list);
            handleOpenChat(null);
        }
    }, [matrixRoomIdToRemove]);

    /*  This is supposed to handle the current user's online status on page load.
    **  This would basically set own user presence status based on saved value from database.
    **  If the user does not have any presence status set, we'll default to sending "online" to matrix servers
    */
    useEffect(() => {
        const override = currentUser[UserKeys.CHAT_PRESENCE_OVERRIDE];
        if (override === "online" || override === "offline") {
            handleSetOwnUserPresence(override);
        } else if (override === "" || override === null) {
            handleSetOwnUserPresence("online");
        }
    }, []);

    const handleSelectChange = (event) => {
        handleChatListSelectChange(event.target.value);
    };

    const handleSearch = (e) => {
        const searchKey = e.target.value;
        if (searchKey.length) {
            setIsChatListSearched(true);
            const filteredChats = userChatsList.filter(chat =>
                // searches for chats whose users' firstname or lastname matches the given search key
                chat.users.some(user => 
                    user.employeeDetails.firstName.toLowerCase().includes(searchKey.toLowerCase())
                        || user.employeeDetails.lastName.toLowerCase().includes(searchKey.toLowerCase())
                )    
            );
            setSearchedChatList(filteredChats);
        } else {
            setIsChatListSearched(false);
            setSearchedChatList(userChatsList);
        }
    };

    const showCreateChatButton = () => {
        const userPermissions = currentUser[UserKeys.PERMISSIONS];
        const userLeadershipRoles = currentUser[UserKeys.LEADERSHIP_ROLES];

        if (selectedChatListTypeOrLeadershipUuid === USER_TYPE_EMPLOYEES) {
            return isChatActionAllowed(MESSAGE_EMPLOYEES, userPermissions);
        } else if (selectedChatListTypeOrLeadershipUuid === USER_TYPE_CLIENTS) {
            return isChatActionAllowed(MESSAGE_CLIENTS, userPermissions);
        } else {
            //leadershipRole

            // Check if selectedChatListTypeOrLeadershipUuid exists in userLeadershipRoles
            return userLeadershipRoles.some(userLeadershipRole =>
                selectedChatListTypeOrLeadershipUuid === userLeadershipRole.uuid
            );
        }
    };

    const hasAnyChatPermission = (permission) => {
        return permission.includes(FULL_ACCESS_BUSINESS_CHAT) || permission.includes(CREATE_GROUP_CHAT) || permission.includes(MESSAGE_EMPLOYEES) || permission.includes(MESSAGE_CLIENTS);
    };

    const showCreateOneToOneChatButton = () => {
        const userPermissions = currentUser[UserKeys.PERMISSIONS];
        const userLeadershipRoles = currentUser[UserKeys.LEADERSHIP_ROLES];
        return userLeadershipRoles.find((item) => (item.name === selectedChatListTypeOrLeadershipUuid || item.uuid === selectedChatListTypeOrLeadershipUuid)) || hasAnyChatPermission(userPermissions);
    };

    const handleSetOwnUserPresence = (status) => setUserPresence(onlineUsersMatrixId, status, matrixClient.getUserId(), handleSettingCurrentUserStatus, currentUser);

    const getUnreadCount = (selectedChatListTypeOrLeadershipUuid) => {

        const isNonLeadership = [USER_TYPE_CLIENTS, USER_TYPE_EMPLOYEES].includes(selectedChatListTypeOrLeadershipUuid);
        try 
        {
            if (isNonLeadership) {
                const nonLeadershipType = activeChatsTotalUnread.find(chatList => chatList.label === selectedChatListTypeOrLeadershipUuid);
                return nonLeadershipType ? nonLeadershipType.totalUnreadCount : 0;
            }

            //leadership
            const findLeadershipRole = leadershipRoles.find(role => role.uuid === selectedChatListTypeOrLeadershipUuid);
            if (!findLeadershipRole) {
                console.warn(`Leadership Role: ${selectedChatListTypeOrLeadershipUuid} not found when trying to get the total unread count.`, leadershipRoles, selectedChatListTypeOrLeadershipUuid);
                return 0;
            }
            
            const leadershipList = activeChatsTotalUnread.find(chatList => chatList.label === findLeadershipRole.uuid);
            return leadershipList ? leadershipList.totalUnreadCount : 0;
        } catch (e) {
            console.warn("Error in getting total unread count of Chat list.", e, selectedChatListTypeOrLeadershipUuid, activeChatListDetails);
            return 0;
        }
    };

    const employeeUnreadCount = getUnreadCount(USER_TYPE_EMPLOYEES);
    const clientUnreadCount = getUnreadCount(USER_TYPE_CLIENTS);

    const renderActiveChatList = () => {
        if (activeChatListDetails.length === 0) {
            return <StyledMenuItem disabled value="No list available">
                <em>None</em>
            </StyledMenuItem>;
        }

        const employeeChatListDetails = activeChatListDetails.find(chatList => chatList.label === USER_TYPE_EMPLOYEES);
        const clientChatListDetails = activeChatListDetails.find(chatList => chatList.label === USER_TYPE_CLIENTS);
         
        const showEmployees = currentUser[UserKeys.PERMISSIONS].includes(MESSAGE_EMPLOYEES) ||
                    employeeChatListDetails.data.chats.length > 0 ||
                    !currentUser.isClient;

        const showClients = currentUser[UserKeys.PERMISSIONS].includes(MESSAGE_CLIENTS) ||
                    clientChatListDetails.data.chats.length > 0 ||
                    currentUser.isClient;

        const prepareListItems = activeChatListDetails.map((activeChat) => {
            if (activeChat.label === USER_TYPE_EMPLOYEES) {
                return showEmployees ? <StyledMenuItem key={USER_TYPE_EMPLOYEES} value={USER_TYPE_EMPLOYEES}>Employees { employeeUnreadCount > 0 && <UnreadCount unreadCount={employeeUnreadCount} />}</StyledMenuItem> : [];
            } else if (activeChat.label === USER_TYPE_CLIENTS) {
                
                return showClients ? <StyledMenuItem key={USER_TYPE_CLIENTS} value={USER_TYPE_CLIENTS}>Clients { clientUnreadCount > 0 && <UnreadCount unreadCount={employeeUnreadCount} />}</StyledMenuItem> : [];
            } else {
                const role = leadershipRoles.findLast(leadershipRole => leadershipRole.uuid === activeChat.label);
                if (!role) {
                    console.warn("Unable to create list item for role: ", activeChat.label);
                }

                const isVisibleToThisUser = role.visibleLeaderCount > 0 || role.currentUserIsLeader;
                const leadershipRoleUnreadCount = getUnreadCount(role.uuid);
                const menuContent = <>{ role.name } {leadershipRoleUnreadCount > 0 && <UnreadCount unreadCount={leadershipRoleUnreadCount} />}</>;
                
                return !isVisibleToThisUser ? [] : <StyledMenuItem 
                    key={role.uuid} 
                    value={role.uuid} 
                    name={role.name}> { menuContent }
                </StyledMenuItem>;
            }
        });
        
        return prepareListItems;
    };
    
    return (
        <Container>
            <CompanyLogoContainer backgroundColor={businessBackgroundColor}>
                { currentUser[UserKeys.COMPANY_LOGO_URL] && <CompanyLogo src={currentUser[UserKeys.COMPANY_LOGO_URL]} /> }
            </CompanyLogoContainer>
            <div style={{ display: "flex", justifyContent: "space-between", margin: "0.4375rem 0.9375rem 0 0.9375rem" }}>
                <UserAvatar firstName={currentUser[UserKeys.FIRST_NAME]} borderPixel="2px"
                    lastName={currentUser[UserKeys.LAST_NAME]}
                    photo={currentUser[UserKeys.PROFILE_PICTURE_URL]}
                    height="4rem" width="4rem" withShadow={true}
                    onClickUserAvatarFunction={() => displayProfileModal(currentUser, currentUser.leadershipRoles, true)}
                    isOnline={amIOnline}
                    showPresenceStatus={true}
                    handleSetOwnUserPresence={handleSetOwnUserPresence}
                />
                <div onClick={() => handleShowBackupKeyModal(true)} style={{ width: "1.73rem", height: "1.73rem", textAlign: "right", cursor: "pointer" }}>
                    <Icon width="1rem" height="1rem" color="#000"
                        src={SettingsIcon}
                    />
                </div>
            </div>
            <Box sx={{ margin: "0 0.9375rem" }}>
                <StyledText size="1rem" margin="1.4375rem 0 0.8125rem 0" weight="600">Active Chats</StyledText>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <ActiveChatListSelect value={selectedChatListTypeOrLeadershipUuid} variant="outlined"
                        style={selectStyle}
                        onChange={handleSelectChange}
                        classes={{ outlined: classes.outlined }}
                        SelectDisplayProps={{
                            style: {
                                background: "transparent",
                            },
                        }}
                    >
                        { renderActiveChatList() }
                    </ActiveChatListSelect>
                    <CreateChatButtons key={"create-group-chat"} className="flex-centered-content"
                        onClick={() => showCreateChatModalOrShowLeadershipEmployees(GROUP_CHAT, selectedChatListTypeOrLeadershipUuid)}
                        isAllowed={isChatActionAllowed(CREATE_GROUP_CHAT, currentUser[UserKeys.PERMISSIONS]) && showCreateChatButton()}
                    >
                        <IconButton color="#fff" src={UserGroupIcon}></IconButton>
                    </CreateChatButtons>
                    
                    <CreateChatButtons key={"create-one-on-one-chat"} className="flex-centered-content"
                        onClick={() => showCreateChatModalOrShowLeadershipEmployees(ONE_ON_ONE_CHAT, selectedChatListTypeOrLeadershipUuid)}
                        isAllowed={showCreateOneToOneChatButton()}
                    >
                        <IconButton color="#fff" src={UserSingleIcon}></IconButton>
                    </CreateChatButtons>
                </div>
                <StyledInput placeholder="Search people" onChange={(e) => handleSearch(e)} />
            </Box>
            
            <Divider />
            { showLoadingState || isUpdatingChatlist || isCurrentlyCreatingChat || isFetchingChats ? <LoadingIndicator /> :
                <ListOfChats
                    chatsList={isChatListSearched ? searchedChatList : userChatsList}
                />
            }
        </Container>
    );
};

export default ChatList; 