import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { API_URL } from "src/scenes/App";

import NoticeboardTable from "./components/NoticeboardTable";
import DeleteDialog from "./components/DeleteDialog";
import NoticeModal from "./components/NoticeModal";
import NoticeViewershipModal from "./components/NoticeViewershipModal";

import { Toast, PageContainer, PageHeadingLabel, ExplainParagraphLabel } from "src/components";

const InputSelector = styled.select`
    height: 3.188rem !important;
    width: 19.7rem !important;
    font-size: 18px !important;
`;

export const FILTER_NOTICE = {
    EMPLOYEE: "employee",
    CLIENT: "client"
};

export const FILTER_BY = {
    ALL_NOTICES: "All",
    ACTIVE_POSTS: "Active Posts",
    SCHEDULED_POSTS: "Scheduled Posts"
};

const PageParagraph = styled(ExplainParagraphLabel)`
    width: 48.25em;
`;

const DEFAULT_RESPONSE_MESSAGE = {
    SUBJECT_INVALID: { "input": "subjectInvalid", "message": "" },
    MESSAGE_INVALID: { "input": "messageInvalid", "message": "" },
    SCHEDULE_INVALID: { "input": "scheduleDateInvalid", "message": "" }
};

const Noticeboard = ({ user }) => {
    const [filterOption, setFilterOption] = useState(FILTER_NOTICE.EMPLOYEE);
    const [isFetching, setIsFetching] = useState(false);
    const [employeesArray, setEmployeesArray] = useState([]);
    const [clientsArray, setClientsArray] = useState([]);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [isRequestingDeletion, setIsRequestingDeletion] = useState(false);
    const [checkAllNotices, setCheckAllNotices] = useState(false);
    const [checkedNotices, setCheckedNotices] = useState([]);
    const [showNoticeModal, setShowNoticeModal] = useState(false);
    const [showNoticeViewershipModal, setShowNoticeViewershipModal] = useState(false);
    const [noticeData, setNoticeData] = useState(null);
    const [isFormLoading, setIsFormLoading] = useState(false);
    const [responseMessage, setResponseMessage] = useState(DEFAULT_RESPONSE_MESSAGE);
    const [filterByOption, setFilterByOption] = useState(FILTER_BY.ALL_NOTICES);
    
    const updateMessage = (messageType, newMessage) => {
        setResponseMessage((prevState) => ({
            ...prevState,
            [messageType]: {
                ...prevState[messageType],
                message: newMessage,
            },
        }));
    };

    useEffect(() => {
        fetchData(FILTER_NOTICE.EMPLOYEE);
        fetchData(FILTER_NOTICE.CLIENT);
    }, [user.token]);


    function setUsersArray(userFilterOption, responseData) {
        if (userFilterOption === FILTER_NOTICE.EMPLOYEE) {
            setEmployeesArray(responseData);
        } else {
            setClientsArray(responseData);
        }
    }

    function getUsers() {
        let unsortedUserArray = [];
        if (filterOption === FILTER_NOTICE.EMPLOYEE) {
            unsortedUserArray = employeesArray;
        } else {
            unsortedUserArray = clientsArray;
        }

        if (!unsortedUserArray)
            return [];

        return sortByDate(unsortedUserArray);
    }

    const sortByDate = (unsortedUserArray) => {
        return unsortedUserArray.sort((a, b) => {
            // Parse the date strings for accurate comparison
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            
            // Return -1 if a is earlier, 1 if b is earlier, 0 if equal
            return dateB - dateA;
        });
    };

    let usersArray = filteredUsers();
    
    function filteredUsers() {
        const unfilteredUserArray = getUsers();
        
        const filteredUserArray = unfilteredUserArray.filter(notice => {
            switch (filterByOption) {
                case FILTER_BY.SCHEDULED_POSTS:
                    return !notice.isActive;
    
                case FILTER_BY.ACTIVE_POSTS:
                    return notice.isActive;
    
                default:
                    return true;
            }
        });

        return filteredUserArray;
    }

    const fetchData = async (userFilterOption) => {
        const noticeboardApi = `${API_URL}/company/noticeboard/${userFilterOption === FILTER_NOTICE.EMPLOYEE ? "employees" : "clients"}`;
        
        setIsFetching(true);
        try {
            const res = await axios.get(noticeboardApi, {
                headers: {
                    Authorization: "Bearer " + user.token
                }
            });
            
            setUsersArray(userFilterOption, res.data.notices);
        } catch (error) {
            // Handle error
        } finally {
            setIsFetching(false);
        }
    };

    const handleFilterChange = event => {
        resetListingState();
        setFilterOption(event.target.value);
    };

    const handleFilterByChange = event => {
        resetListingState();
        setFilterByOption(event.target.value);
    };

    const resetListingState = () => {
        setCheckAllNotices(false);
        setCheckedNotices([]);
    };

    const handleMultipleDelete = async (noticeUuids) => {
        try {
            setIsRequestingDeletion(true);
            await axios.delete(`${API_URL}/company/noticeboard`, {
                data: { noticeUuids },
                headers: {
                    Authorization: "Bearer " + user.token
                }
            });

            Toast.success("Selected post has been deleted");
        } catch (error) {
            // Handle error
            Toast.error("Unable to process deletion of noticeboard");
        } finally {
            setShowNoticeModal(false);
            setIsRequestingDeletion(false);
            fetchData(filterOption);
            setCheckedNotices([]);
            handleCloseDeleteDialog();
        }
    };

    const handleCheckAllNotices = (notices) => {
        const toggleState = !checkAllNotices;
        setCheckAllNotices(toggleState);
        const newCheckedUuids = toggleState ? notices.map(notice => notice.uuid) : [];
        setCheckedNotices(newCheckedUuids);
    };

    const handleCheckboxChange = (noticeUuid) => {
        let newCheckedUuids = [];
        
        if (!checkedNotices.includes(noticeUuid)) {
            newCheckedUuids = [...checkedNotices, noticeUuid];
        } else {
            newCheckedUuids = checkedNotices.filter(uuid => uuid !== noticeUuid);
        }

        setCheckedNotices(newCheckedUuids);
        
        //uncheck all/check all
        if (newCheckedUuids.length === getUsers().length) {
            setCheckAllNotices(true);
        } else {
            setCheckAllNotices(false);   
        }
    };

    const handleDelete = () => {
        if (noticeData !== null) {
            handleMultipleDelete([noticeData.uuid]);
        } else {
            handleMultipleDelete(checkedNotices);
        }
    };

    const handleShowDeleteDialog = () => {
        if (checkedNotices.length === 0)
            return alert("Please select a noticeboard to delete.");

        setShowDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setShowDeleteDialog(false);
    };

    const handleEditNotice = notice => {
        setNoticeData(notice);
        setShowNoticeModal(true);
    };

    const handleNewNotice = () => {
        setNoticeData(null);
        setShowNoticeModal(true);
    };

    const handleCloseNoticeModal = () => {
        setShowNoticeModal(false);
    };

    const handleShowNoticeViewershipModal = notice => {
        setNoticeData(notice);
        setShowNoticeViewershipModal(true);
    };

    const handleCloseNoticeViewershipModal = () => {
        setShowNoticeViewershipModal(false);
    };

    const formatDateToYmdTHi = (dateString) => {
        if (!dateString)
            return "";

        const dateObject = new Date(dateString);

        const year = dateObject.getFullYear().toString().padStart(4, "0");
        const month = String(dateObject.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed
        const day = String(dateObject.getDate()).padStart(2, "0");
        const hours = String(dateObject.getHours()).padStart(2, "0");
        const minutes = String(dateObject.getMinutes()).padStart(2, "0");


        const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`; //"Y-m-dTH:i"

        return formattedDate;
    };

    /** save noticeboard first, then start uploading the files */
    const uploadAttachedFileToS3AndSave = async (noticeUuid, attachedFile) => {
        const responseOfAttachment = await axios.get(`${API_URL}/company/noticeboard/web/attachmentsS3PresignedUrl/${noticeUuid}/${attachedFile.name}`, {
            headers: {
                Authorization: "Bearer " + user.token
            }
        });

        const attachmentS3PresignedUrl = responseOfAttachment.data.attachmentS3PresignedUrl;
        const storedUrl = await uploadAttachmentToS3(attachmentS3PresignedUrl, attachedFile.preview, attachedFile.type);
        const formRequest = {
            filename: attachedFile.name, 
            s3Url: storedUrl,
        };

        attachmentCreate(noticeUuid, [formRequest]);
    };

    /** save noticeboard first, then start deleting the files */
    const deleteAttachedFile = async (noticeUuid, deletedFile) => {
        await axios.delete(`${API_URL}/company/noticeboard/web/attachment/${noticeUuid}/${deletedFile.attachmentUuid}`, {
            headers: {
                Authorization: "Bearer " + user.token
            }
        });
    };

    const handleCreateOrUpdate = async (formData, imageFile, modalSetErrors, attachedFiles, deleteAttachedFiles) => {
        setIsFormLoading(true);
        setResponseMessage(DEFAULT_RESPONSE_MESSAGE);
        
        try {
            //Update Notice
            if (noticeData !== null) {
                let updatedNotice = null;
                let formRequest = {
                    ...formData, 
                    "uuid": noticeData.uuid,
                    "mediaHasChanged": imageFile !== null,
                    "readerType": filterOption,
                    "scheduleDate": formatDateToYmdTHi(formData.scheduleDate)
                };

                const readerTypeUrl = noticeData.readerType === "employee" ? "employees" : "clients";
                const response = await axios.put(`${API_URL}/company/noticeboard/web/${readerTypeUrl}/${noticeData.uuid}`, formRequest, {
                    headers: {
                        Authorization: "Bearer " + user.token
                    }
                });

                updatedNotice = response.data.announcement;
       
                if (imageFile !== null) {
                    const fullUrl = response.data.s3PresignedUrl;
                    updatedNotice = await uploadToS3AndPutNoticeboard(fullUrl, formRequest, imageFile);
                }

                await uploadRelatedMedia(updatedNotice.uuid, attachedFiles, deleteAttachedFiles);

                fetchData(noticeData.readerType ? FILTER_NOTICE.EMPLOYEE : FILTER_NOTICE.CLIENT);
            } else { //Create Notice
                let formRequest = {
                    ...formData
                };

                if (formData.visibleToEmployees) {
                    formRequest.readerType = FILTER_NOTICE.EMPLOYEE;
                    const newNoticeboardEmployeesResponse = await axios.post(`${API_URL}/company/noticeboard/employees`, formRequest, {
                        headers: {
                            Authorization: "Bearer " + user.token
                        }
                    });

                    if (imageFile !== null) {
                        const fullUrl = newNoticeboardEmployeesResponse.data.s3PresignedUrl;
                        
                        formRequest.uuid = newNoticeboardEmployeesResponse.data.notice.uuid;

                        await uploadToS3AndPutNoticeboard(fullUrl, formRequest, imageFile);
                    }

                    await uploadRelatedMedia(newNoticeboardEmployeesResponse.data.notice.uuid, attachedFiles, deleteAttachedFiles);

                    fetchData(FILTER_NOTICE.EMPLOYEE);
                }

                if (formData.visibleToClients) {
                    formRequest.readerType = FILTER_NOTICE.CLIENT;
                    const newNoticeboardClientsResponse = await axios.post(`${API_URL}/company/noticeboard/clients`, formRequest, {
                        headers: {
                            Authorization: "Bearer " + user.token
                        }
                    });

                    if (imageFile !== null) {
                        const fullUrl = newNoticeboardClientsResponse.data.s3PresignedUrl;
                        
                        formRequest.uuid = newNoticeboardClientsResponse.data.notice.uuid;
  
                        await uploadToS3AndPutNoticeboard(fullUrl, formRequest, imageFile);
                    }

                    await uploadRelatedMedia(newNoticeboardClientsResponse.data.notice.uuid, attachedFiles, deleteAttachedFiles);

                    fetchData(FILTER_NOTICE.CLIENT);
                }
            }

            setShowNoticeModal(false);
            setIsFormLoading(false);
        } catch (error) {

            if (error?.response?.status === 403) { //see App.js::handle403()
                const errorMessage = error.response.data["error"];
                Toast.error(errorMessage);
            }

            // Handle non-403 error
            let newErrors = [];
            let dataErrorFields = error.fields;
            
            dataErrorFields && dataErrorFields.forEach(function(fieldKey, index) {
                switch (fieldKey) {
                    case "subjectInvalid":
                        updateMessage("SUBJECT_INVALID", error.messages[index]);
                        return;
                    case "messageInvalid":
                        updateMessage("MESSAGE_INVALID", error.messages[index]);
                        return;
                    case "scheduleDateInvalid":
                        updateMessage("SCHEDULE_INVALID", error.messages[index]);
                        return;
                    default:
                    //NO ERROR TO CATCH
                    return;
                }
            });
            
            modalSetErrors(newErrors);
            setIsFormLoading(false);
        }
    };

    async function uploadRelatedMedia(noticeUuid, attachedFiles, deleteAttachedFiles) {
        if (attachedFiles) {
            for (const attachedFile of attachedFiles) {
                await uploadAttachedFileToS3AndSave(noticeUuid, attachedFile);
            }
        }

        if (deleteAttachedFiles) {
            for (const deleteAttachedFileObj of deleteAttachedFiles) {
                await deleteAttachedFile(noticeUuid, deleteAttachedFileObj);
            }
        }
    }

    async function uploadAttachmentToS3(fullUrl, filePreview, fileType) {
        await axios.put(fullUrl, filePreview, {
            headers: { "Content-Type": fileType }
        });
    
        const storedUrl = fullUrl.substring(0, fullUrl.indexOf("?"));
        return storedUrl;
    }

    async function uploadToS3AndPutNoticeboard(fullUrl, formRequest, imageFile) {
        const storedUrl = fullUrl.substring(0, fullUrl.indexOf("?"));
        
        // upload image
        await axios.put(fullUrl, imageFile, {
            headers: { "Content-Type": imageFile.type }
        });

        formRequest.url = storedUrl;
        const responsePutCompanyNoticeboard = await axios.put(`${API_URL}/company/noticeboard`, formRequest, {
            headers: {
                Authorization: "Bearer " + user.token
            }
        });

        return responsePutCompanyNoticeboard.data.notice;
    }

    async function attachmentCreate(noticeUuid, formRequest) {
        const responsePutCompanyNoticeboard = await axios.post(`${API_URL}/company/noticeboard/web/attachment/${noticeUuid}`, formRequest, {
            headers: {
                Authorization: "Bearer " + user.token
            }
        });

        return responsePutCompanyNoticeboard;
    }

    return (
        <PageContainer>
            {
                <>
                    <PageHeadingLabel>
                        {filterOption === FILTER_NOTICE.EMPLOYEE ? "Employee" : "Client" } Noticeboard
                    </PageHeadingLabel>

                    <div className="row" style={{ maxWidth: "94.5rem", justifyContent: "space-between", marginTop: "0" }}>

                        <PageParagraph style={{ fontSize: "0.8750em", marginBottom: "1em" }}>
                            Boost your teams spirit, productivity, and client connections with the Business Noticeboard. 
                            Recognise achievements, share updates, and foster a positive outlook through posts, images, and videos to your employees and clients.
                        </PageParagraph>

                        <div className="field">
                            <label style={{ fontSize: "1rem" }}>Selected Noticeboard:</label>
                            <InputSelector value={filterOption} onChange={handleFilterChange}>
                                <option value={FILTER_NOTICE.EMPLOYEE}>Employee Noticeboard ({employeesArray.length})</option>
                                <option value={FILTER_NOTICE.CLIENT}>Client Noticeboard ({clientsArray.length})</option>
                            </InputSelector>
                        </div>
                    </div>

                    <NoticeboardTable
                        user={user}
                        usersArray={usersArray}
                        handleNewNotice={handleNewNotice}
                        handleEditNotice={handleEditNotice}
                        handleShowDeleteDialog={handleShowDeleteDialog}
                        
                        checkedNotices={checkedNotices}
                        handleCheckboxChange={handleCheckboxChange}
                        handleCheckAllNotices={handleCheckAllNotices}
                        checkAllNotices={checkAllNotices}
                        isFetching={isFetching}
                        filterOption={filterOption}
                        filterByOption={filterByOption}
                        handleFilterByChange={handleFilterByChange}
                        handleShowNoticeViewershipModal={handleShowNoticeViewershipModal}
                    />
                </>
            }
            
            {showDeleteDialog && 
                <DeleteDialog
                    showDeleteDialog={showDeleteDialog}
                    handleCloseDeleteDialog={handleCloseDeleteDialog}
                    handleDelete={handleDelete}
                    isMultipleDelete={checkedNotices.length > 1}
                    isRequestingDeletion={isRequestingDeletion}
                />
            }

            { showNoticeModal && 
                <NoticeModal
                    showNoticeModal={showNoticeModal}
                    handleCloseModal={handleCloseNoticeModal}
                    noticeData={noticeData}
                    filterOption={filterOption}
                    handleCreateOrUpdate={handleCreateOrUpdate}
                    isFormLoading={isFormLoading}
                    handleDelete={handleDelete}
                    setShowDeleteDialog={setShowDeleteDialog}
                    responseMessage={responseMessage}
                    emptyResponseMessage={ () => { setResponseMessage(DEFAULT_RESPONSE_MESSAGE); } }
                />
            }
            
            { showNoticeViewershipModal && 
                <NoticeViewershipModal
                    user={user}
                    noticeData={noticeData}
                    showNoticeViewershipModal={showNoticeViewershipModal}
                    handleCloseNoticeViewershipModal={handleCloseNoticeViewershipModal}
                />
            }
        </PageContainer>
    );
};

export default withRouter(Noticeboard);