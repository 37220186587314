import React from "react";
import axios from "axios";
import { API_URL } from "src/scenes/App";
import { Box, Grid, Dialog, Divider } from "@material-ui/core/";
import { useState, useEffect } from "react";
import {
    Button, FormField, Text, CountryPhoneInput, LoadingIndicator
} from "src/components";
import styled, { css } from "styled-components";
import Toast from "src/components/Toast";

const Label = styled.label`
    font-family: Roboto, Helvetica, sans-serif;
    font-size: 0.875rem;
    font-weight: 700;
    color: #000;
    margin: 0;
`;

const Required = styled.span`
    color: red;
    font-style: italic;
    font-weight: 400;
    ::before {
        content: "(required)";
        font-size: 0.8rem;
    }
`;

const RadioButton = styled.button`
    position: relative;
    width: 15.625rem;
    height: 2.5rem;
    border: 1px solid #000;
    border-radius: 12px;
    text-align: center;
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: 400;
    background-color: #fff;
    cursor: pointer;

    ${(props) =>
        props.active &&
        css`
            background-color: #000000;
            color: #fff !important;
            font-weight: 700;
        `}
    ${(props) =>
        props.disabled &&
        css`
            pointer-events: none;
            color: #000;
        `}
`;

const StyledInput = styled(FormField)`
    width: 15.625rem;
    height: 2.5rem;
    border: 1px solid #000;
    border-radius: 12px;
    text-align: left;
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    color: #000;
    padding: 0 30px;

    ::placeholder {
        text-align: left;
        color: #737373;
        font-family: Roboto, Helvetica, sans-serif;
    }
`;

const StyledCountryPhoneInput = styled(CountryPhoneInput)`
    margin: 5px 0;

    div.flag-dropdown, input.form-control {
        background-color: #FFF;
    }

    input.form-control {
        width: 200px !important;
        height: 2.5rem;
        text-align: left;
        font-weight: 400;
        font-size: 0.875rem;
        color: #000;
        padding: 0 30px;
    }

    .form-control::placeholder {
        text-align: left;
        color: #737373;
        font-size: 0.875rem;
        font-family: Roboto, Helvetica, sans-serif;
    }
`;

const Circle = styled.div`
    position: absolute;
    right: 0;
    bottom: 9px;
    margin-right: 10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #76C00D;
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;

    ${(props) =>
        props.active &&
        css`
            visibility: visible;
        `}
    ${(props) =>
        props.disabled &&
        css`
            pointer-events: none;
        `}
`;

const CheckMark = styled.span`
    color: white;
    font-weight: 700;
    font-size: 10px;
`;

const ClientDetailsForm = (props) => {
    const { showDialog, handleClose, client, enableSuccessModal, isClientFormEdit, shortTermDurationUnit, countryCode, token } = props;
    const [formData, setFormData] = useState(props.client);
    const [errorList, setErrorList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleInputChange = (event, key) => {
        const updatedFormData = { ...formData, [key]: event.target.value };
        setFormData(updatedFormData);
    };

    const handlePhoneNumber = (phone) => {
        if (phone !== "" && !phone.startsWith("+")) {
            phone = "+" + phone;
        }

        const updatedFormData = { ...formData, phoneNumber: phone };
        setFormData(updatedFormData);
    };

    const handleFormSubmit = () => {
        setErrorList([]);
        const requiredFields = ["firstName", "lastName", "email"];
        let status = true;
        requiredFields.forEach((field) => {
            if (!formData[field].trim()) {
                status = false;
            }
        });

        if (!status) {
            return setErrorList((prevErrorList) => [...prevErrorList, "Please fill all required fields."]);
        }
        if (!validateEmail(formData.email)) {
            return setErrorList((prevErrorList) => [...prevErrorList, "Invalid email format."]);
        }
        setIsLoading(true);
        return isClientFormEdit ? updateClient() : createClient();
    };

    const createClient = () => {
        axios.post(`${API_URL}/company/clientLicense`, formData, {
            headers: {
                Authorization: "Bearer " + token
            }
        })
            .then(() => {
                setIsLoading(false);
                enableSuccessModal(formData);
            })
            .catch((error) => {
                setIsLoading(false);
                return setErrorList([error.message]);
            });
    };

    const updateClient = () => {
        axios.put(`${API_URL}/company/clientLicense/${formData.uuid}`, formData, {
            headers: {
                Authorization: "Bearer " + token
            }
        })
            .then(() => {
                setIsLoading(false);
                enableSuccessModal(formData);
            })
            .catch(error => {
                setIsLoading(false);
                return setErrorList([error.message]);
            });
    };

    const handleDeleteClient = () => {
        const isConfirmed = window.confirm("Are you sure you want to remove the client?");
        if (!isConfirmed) {
            return false;
        }
        setIsLoading(true);
        axios.delete(`${API_URL}/company/clientLicense/${formData.uuid}`, {
            headers: {
                Authorization: "Bearer " + token
            }
        })
            .then(() => {
                setIsLoading(false);
                props.refreshTableOnClientDelete();
            })
            .catch(error => {
                setIsLoading(false);
                Toast.error(error);
            });
    };

    const validateEmail = (email) => {
        return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
    };

    // prevents console warns when one of the input values is null
    const filterInputValue = (value) => [null].includes(value) ? "" : value;

    const renderErrors = errorList.map((error, index) => {
        return errorList.length ? <div key={index} style={{ color: "red", fontFamily: "Roboto, Helvetica, sans-serif" }}>{ error }</div>
            : "";
    });

    useEffect(() => {
        setFormData(client);
    }, [props]);

    return (
        <Dialog
            open={showDialog}
            onClose={handleClose}
            maxWidth="md"
            scroll="body"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            PaperProps={{
                style: {
                    width: 900,
                    height: "auto",
                    borderRadius: 15,
                    overflow: "visible"
                }
            }}
        >
            <Box sx={{ margin: "3.8125rem 3.4375rem 2rem 3.4375rem" }}>
                <Text color="#11141A" size="2.5rem" align="left" weight="700" margin="0">
                    {!isClientFormEdit ? "Add New Trial Client" : "Edit User"}
                </Text>
                {!isClientFormEdit ? (
                    <Text color="#000000" align="left" size="0.875rem">
                        Enter the relevant Client details accordingly. Adding a new client will deduct 1 license.
                    </Text>
                ) : (
                    <div style={{ marginBottom: "1.5rem" }}></div>
                )}
            </Box>
            <Divider />
            <Box sx={{ margin: "1.28125rem 3.4375rem" }}>
                <Grid container spacing={2}>
                    <Grid item sm={12} md={4}>
                        { !isClientFormEdit && <Label>Select User Type</Label> }
                        <input type="radio" name="userType" value="client" style={{ visibility: "hidden" }} />
                        <RadioButton active={true}
                            style={{ margin: "1.375rem 0" }}
                        >
                            Client
                            <Circle active={true}
                            ><CheckMark>&#x2713;</CheckMark></Circle>
                        </RadioButton>
                    </Grid>
                </Grid>
    
                <Grid container spacing={2}>
                    <Grid item sm={12} md={4}>
                        <Label>First Name <Required /></Label>
                        <StyledInput placeholder="First Name"
                            value={filterInputValue(formData.firstName)}
                            onChange={e => handleInputChange(e, "firstName")}
                            style={{ textTransform: "capitalize" }}
                        />
                    </Grid>
                    <Grid item sm={12} md={4}>
                        <Label>Last Name <Required /></Label>
                        <StyledInput required placeholder="Last Name"
                            value={filterInputValue(formData.lastName)}
                            onChange={e => handleInputChange(e, "lastName")}
                            style={{ textTransform: "capitalize" }}
                        />
                    </Grid>
                    <Grid item sm={12} md={4}>
                        <Label>Email <Required /></Label>
                        <StyledInput required placeholder="Email"
                            value={filterInputValue(formData.email)}
                            onChange={e => handleInputChange(e, "email")}
                        />
                    </Grid>
                </Grid>
    
                <Grid container spacing={2} style={{ marginBottom: "1.8rem" }}>
                    <Grid item sm={12} md={4}>
                        <Label>Mobile</Label>
                        
                        <div>
                            <StyledCountryPhoneInput
                                autoFormat={false}
                                prefix={"+"}
                                country={countryCode.toLowerCase()}
                                value={filterInputValue(formData.phoneNumber)}
                                onChange={phone => handlePhoneNumber(phone)}
                                placeholder="Mobile Number"
                                errors={[]}
                            />
                        </div>
                    </Grid>
                    <Grid item sm={12} md={4}>
                        <Label>Client Type (Optional)</Label>
                        <StyledInput placeholder="Client Type"
                            value={filterInputValue(formData.clientType)}
                            onChange={e => handleInputChange(e, "clientType")}
                        />
                    </Grid>
                    <Grid item sm={12} md={4}>
                        <Label>License Duration</Label>
                        <StyledInput placeholder="License Duration"
                            value={isClientFormEdit ? formData.licenseDuration : `${shortTermDurationUnit}` }
                            readOnly={true}
                        />
                    </Grid>
                </Grid>
    
                {renderErrors}
                <Divider />
                <Box sx={{ margin: "1.375rem 0 1.875rem 0" }}>
                    { isLoading ? <LoadingIndicator containerHeight="2rem" margin="0" />
                        :
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div>
                                {isClientFormEdit && (
                                    <Button backgroundColor="#FF5353" color="#fff"
                                        style={{ borderRadius: 10 }}
                                        width="9.375rem" height="2.3125rem"
                                        onClick={() => handleDeleteClient()}
                                    >
                                        Delete User
                                    </Button>
                                )}
                            </div>
                            <div style={{ textAlign: "right" }}>
                                <Button
                                    border="1px solid #000"
                                    color="#000"
                                    marginRight="15px"
                                    width="8.75rem"
                                    onClick={handleClose}
                                    style={{ borderRadius: "7px", boxShadow: "none" }}
                                    className="form-btns disabled"
                                >
                                    Cancel
                                </Button>
                                <Button backgroundColor="#006CFF" color="#fff"
                                    border="1px solid #006CFF"
                                    width="8.75rem"
                                    style={{ borderRadius: "7px", boxShadow: "none" }}
                                    type="submit"
                                    onClick={() => handleFormSubmit()}
                                    className="form-btns disabled"
                                >
                                    {isClientFormEdit ? "Save" : "Add User"}
                                </Button>
                            </div>
                        </div>
                    }
                    
                </Box>
            </Box>
        </Dialog>
    );
};

export default ClientDetailsForm;
