import React, { Component } from "react";
import styled from "styled-components";

const RealTimeDataButton = styled.button`
    height: 49px;
    width: 150px;
    color: #36BE24;
    background-color: #E6FFE3;
    border: 2px solid #36BE24;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    font: normal normal bold 15px/18px Roboto, Helvetica, sans-serif;
    letter-spacing: 0;
`;

export class RealTimeBadge extends Component {
    render() {
        return (
            <RealTimeDataButton>
                Real-Time Data
            </RealTimeDataButton>
        );
    }
}