import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { List, ListItem } from "@material-ui/core";
import { Text } from "src/components";
import ListAvatar from "./ListAvatar";
import ListText from "./ListText";
import { ChatContext } from "../../ChatContext";
import { UserContext } from "src/scenes/App/UserContext";
import UnreadCount from "./UnreadCount";
import { checkIfUserIsOnline, doesObjectPropertyExist } from "src/utils/helpers";
import { USER_TYPE_EMPLOYEES, USER_TYPE_CLIENTS } from "src/constants/chat";
import { ChatListScrollableContainer } from "../styled/chatListStyled";

export const StyledText = styled(Text)`
    font-weight: ${ p => p.weight || 400 };
    color: ${ p => p.color || "#000000" };
    text-align: left;
    font-size: ${ p => p.size };
    margin: ${ p => p.margin || "1px" };
    text-align: ${ p => p.align || "left"};
`;

const ListOfChats = (props) => {
    const { chatsList } = props;
    const {
        handleOpenChat, 
        currentChat,
        currentUserEmployeeUuid,
        onlineUsersMatrixId,
        selectedChatListTypeOrLeadershipUuid,
        usersList,
        sendReadReceipt
    } = useContext(ChatContext);
    const currentUser = useContext(UserContext);
    const [safeToRenderChatList, setSafeToRenderChatList] = useState(false);

    useEffect(() => {
        if (chatsList && Array.isArray(chatsList)) {
            setSafeToRenderChatList(true);
        }
    }, [chatsList]);

    const renderClassName = (uuid) => {
        if (currentChat && currentChat.uuid === uuid) {
            return "active-chat";
        }
        return "inactive-chat cursor-pointer";
    };

    const beforeOpeningChat = async (chat) => {
        
        if (!doesObjectPropertyExist(chat, "matrixRoomId")) {
            console.warn("Matrix Room ID not found when opening the chat", chat);
            return;
        }
        handleOpenChat(chat); //let's open the chat first, then follow the unread promise.

        //run this silently
        if (chat.unreadCount !== 0) {
            const hasSentReceipt = await sendReadReceipt(chat.matrixRoomId);
            if (!hasSentReceipt) {
                return;
            }
            chat.unreadCount = 0;
        }
    };

    const checkHasLeadershipRoles = (chat) => {
        if (selectedChatListTypeOrLeadershipUuid === USER_TYPE_EMPLOYEES || selectedChatListTypeOrLeadershipUuid === USER_TYPE_CLIENTS) {
            return true;
        }
        return chat.leadershipRoleUuid === selectedChatListTypeOrLeadershipUuid;
    };

    const checkIfUserPresenceOverrideHasSetToOnline = (userMatrixId, chat) => {
        const user = usersList.find((user) => user.matrixUserId === userMatrixId);
      
        if (user) {
            return user.chatPresenceOverride === "online";
        } else {
            const nonCurrentChatTypeUser = chat.users.find((user) => user.matrixUserId === userMatrixId);
      
            return nonCurrentChatTypeUser?.employeeDetails?.chatPresenceOverride === "online";
        }
    };

    const checkIfUserPresenceOverrideHasSetToOffline = (userMatrixId, chat) => {
        const user = usersList.find((user) => user.matrixUserId === userMatrixId);
      
        if (user) {
            return user.chatPresenceOverride === "offline";
        } else {
            const nonCurrentChatTypeUser = chat.users.find((user) => user.matrixUserId === userMatrixId);
      
            return nonCurrentChatTypeUser?.employeeDetails?.chatPresenceOverride === "offline";
        }
    };

    const renderChatsList = (chats) => {
        return chats.map(chat => {
            const { isGroupChat, users, name, latestEvent, recentMessage, unreadCount } = chat;
            const otherChatUsers = users.filter(user => user.employeeUuid !== currentUserEmployeeUuid);
            const otherUserDetails = otherChatUsers.length ? otherChatUsers[0] : null;
            const isThisUserOnline = checkIfUserPresenceOverrideHasSetToOffline(otherUserDetails?.matrixUserId, chat) ? false : checkIfUserPresenceOverrideHasSetToOnline(otherUserDetails?.matrixUserId, chat) || checkIfUserIsOnline(onlineUsersMatrixId, otherUserDetails?.matrixUserId); 
            return (
                <ListItem custom-attribute-key={chat.uuid} key={chat.uuid}
                    className={renderClassName(chat.uuid)}
                    style={{ boxSizing: "border-box", padding: "0.625rem", borderRadius: "0.625rem", display: "flex" }}
                    onClick={() => beforeOpeningChat(chat)}
                >
                    <ListAvatar
                        currentUser={currentUser}
                        users={users}
                        isGroupChat={isGroupChat}
                        currentUserEmployeeUuid={currentUserEmployeeUuid}
                        isOnline={ isThisUserOnline }
                        showPresenceStatus={true}
                    />
                    <ListText chatName={name} latestEvent={latestEvent} recentMessage={recentMessage} showTimestamp={chat.unreadCount === 0} />
                    <div>{ unreadCount > 0 && <UnreadCount unreadCount={unreadCount} />}</div>
                </ListItem>
            );
        });
    };

    const permittedChatList = () => {
        return chatsList.filter(chat => {
            //const showMessagePreviewWhenNotEmpty = chat.recentMessage !== "No messages yet"; //TODO: see message below
            //const ownerAllowedToOpenChat = chat.ownerUuid === currentUserEmployeeUuid; //TODO: see message below
            //const isPermittedToShow = hasLeadershipRoles && (showMessagePreviewWhenNotEmpty || ownerAllowedToOpenChat); //TODO: seen a bug that doesn't display the chatList that was given by the API, will disabling this for now to match
            
            const hasLeadershipRoles = checkHasLeadershipRoles(chat);
            return hasLeadershipRoles;
        });
    };

    const permittedList = permittedChatList();
    return (
        <React.Fragment>
            { safeToRenderChatList &&
                <>
                    { permittedList.length > 0 ?
                        <ChatListScrollableContainer>
                            <List>{ renderChatsList(permittedList) }</List>
                        </ChatListScrollableContainer>
                        :
                        <div className="flex-centered-content" style={{ height: "100%" }}>
                            <StyledText align="center" margin="1.25rem 0">You have no available chats.</StyledText>
                        </div>
                    }
                </>
                
            }
        </React.Fragment>
    );
};

export default ListOfChats;